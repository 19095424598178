import React, { useState, useEffect, useMemo, useCallback, useRef, } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoEasy from 'goeasy';
import { getGoEasyClient, connectGoEasyClient } from "../../utils/getIm";
import "./PrivateChat.css";
import PendingGIF from "../../assets/chat/images/pending.gif";
import { NavBar, Toast } from "antd-mobile";

const MyAvatar = "https://tc-lottery.oss-cn-guangzhou.aliyuncs.com/chat-avatar/Avatar-1.png";
const ShopAvatar = "https://tc-lottery.oss-cn-guangzhou.aliyuncs.com/chat-avatar/Avatar-3.png";
export default function ChatGroup() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const shop = useSelector((state) => state.shop.shop);
    const groupId = useMemo(() => (shop._id).toUpperCase(), [shop._id]);
    const goEasy = useMemo(() => getGoEasyClient(), []);
    const loadHistoryPosition = useRef({ toBottom: true });
    const scrollViewRef = useRef(null);
    const messageListRef = useRef(null);
    const [text, setText] = useState("");
    const shopManager = useMemo(() => ({
        id: shop._id,
        name: shop.name,
        avatar: ShopAvatar
    }), [shop])
    const to = useMemo(() => ({
        type: GoEasy.IM_SCENE.GROUP,
        id: groupId,
        data: { "avatar": MyAvatar, name: '用户' } //群信息, 任意格式的字符串或者对象，用于更新会话列表中的群信息conversation.data
    }), [groupId])
    const [historyMessages, setHistoryMessages] = useState<any[]>([]);
    const [historyLoading, setHistoryLoading] = useState(true);
    const [historyAllLoaded, setHistoryAllLoaded] = useState(false);

    const markPrivateMessageAsRead = useCallback(() => {
        goEasy.im.markMessageAsRead({
            id: groupId,
            type: GoEasy.IM_SCENE.GROUP, 
            onSuccess: function () {
                console.log('标记群聊已读成功');
            },
            onFailed: function (error: any) {
                console.log('标记群聊已读失败', error);
            },
        });
    }, [goEasy.im, groupId]);

    const scrollToBottom = () => {
        if (scrollViewRef && messageListRef) {
            scrollViewRef.current.scrollTop = messageListRef.current.scrollHeight
        }
    }

    const loadHistoryMessage = useCallback(function (toBottom: any) {
        setHistoryLoading(true);
        loadHistoryPosition.current.toBottom = toBottom;
        //历史消息
        let lastMessageTimeStamp: any = null;
        let lastMessage = historyMessages[0];
        if (lastMessage) {
            lastMessageTimeStamp = lastMessage.timestamp;
        }
        goEasy.im.history({
            id: groupId,//用户id或者群id
            type: GoEasy.IM_SCENE.GROUP, //
            lastTimestamp: lastMessageTimeStamp,
            limit: 10,
            onSuccess: (result: { content: any; }) => {
                setHistoryLoading(false);
                let messages = result.content;
                if (messages.length === 0) {
                    setHistoryAllLoaded(true);
                } else {
                    if (lastMessageTimeStamp) {
                        setHistoryMessages(messages.concat(historyMessages));
                    } else {
                        setHistoryMessages(messages);
                    }
                    if (messages.length < 10) {
                        setHistoryAllLoaded(true);
                    }
                    if (toBottom) {
                        scrollToBottom();
                        markPrivateMessageAsRead();
                    }
                }
            },
            onFailed: (error: { code: string; content: string; }) => {
                //获取失败
                setHistoryLoading(false);
                console.log('获取历史消息失败, code:' + error.code + ',错误信息:' + error.content);
            },
        });
    }, [goEasy.im, groupId, historyMessages, markPrivateMessageAsRead])

    const onReceivedPrivateMessage = useCallback((message: { senderId: any; }) => {
        if (message.senderId !== shop._id) {
            setHistoryMessages([...historyMessages, message])
            markPrivateMessageAsRead();
        }
    }, [historyMessages, markPrivateMessageAsRead, shop._id]);

    const sendMessage = (message: any) => {
        setHistoryMessages([...historyMessages, message]);
        loadHistoryPosition.current.toBottom = true;
        goEasy.im.sendMessage({
            message: message,
            onSuccess: (message: any) => {
                scrollToBottom();
                console.log('发送成功', message);
            },
            onFailed: function (error: any) {
                scrollToBottom();
                Toast.show('发送失败:');
            }
        });
    }

    const sendTextMessage = () => {
        if (!text.trim()) {
            Toast.show("输入为空");
            return
        }
        goEasy.im.createTextMessage({
            text: text,
            to: to,
            onSuccess: (message: any) => {
                sendMessage(message);
                setText("");
            },
            onFailed: (err: any) => {
                console.log("创建消息err:", err);
            }
        });
    }


    useEffect(() => {
        const initIM = async () => {
            await connectGoEasyClient(goEasy, shop);
            loadHistoryMessage(true);
            goEasy.im.on(GoEasy.IM_EVENT.GROUP_MESSAGE_RECEIVED, onReceivedPrivateMessage);
            goEasy.im.subscribeGroup({
                groupIds:[groupId],
                onSuccess: function () {  //订阅成功
                console.log("Group message subscribe successfully.");
                },
                onFailed: function (error) { //订阅失败
                console.log("Failed to subscribe group message, code:" + error.code + " content:" + error.content);
                }
            });
        }
        initIM();
        return () => {
            goEasy.im.unsubscribeGroup({
                groupId,
                onSuccess: function (message) {  //取消成功
                   console.log("Group message unsubscribe successfully.");
                },
                onFailed: function (error) { //取消失败
                   console.log("Failed to unsubscribe group message, code:" + error.code + " content:" + error.content);
                }
            });
            goEasy.im.off(GoEasy.IM_EVENT.GROUP_MESSAGE_RECEIVED, onReceivedPrivateMessage);
        }
    }, [goEasy, groupId, loadHistoryMessage, onReceivedPrivateMessage, shop, user]);

    useEffect(() => {
        if (loadHistoryPosition.current.toBottom) {
            scrollToBottom();
            markPrivateMessageAsRead();
        }
    }, [historyMessages, markPrivateMessageAsRead])
    return (
        <div className="chat-container">
            <NavBar onBack={() => navigate(-1)} right={<span onClick={() => navigate('/manager/chat/user')}>用户管理</span>}>
                {shopManager.name}
            </NavBar>
            {/* <div className="chat-title">
                <img src={shopManager.avatar} className="chat-avatar" alt="店主"/>
                <div className="chat-name">{ shopManager.name }</div>
            </div> */}
            <div className="chat-main" ref={scrollViewRef}>
                <div className="message-list" ref={messageListRef}>
                    {
                        historyLoading ? (
                            <div className="history-loading">
                                <img src={PendingGIF} alt="加载中..." />
                            </div>
                        ) : (
                            <div className={historyAllLoaded ? 'history-loaded' : 'load'} onClick={() => loadHistoryMessage(false)}>
                                {historyAllLoaded ? '已经没有更多的历史消息' : '获取历史消息'}
                            </div>
                        )
                    }
                    {
                        historyMessages && historyMessages.map((message) => {
                            const selfMessage = message.senderId === shop._id;
                            return (
                                <div>
                                    {/* <div className="time-tips">{ renderMessageDate(message, index) }</div> */}
                                    <div className="message-item">
                                        <div className={selfMessage ? "self message-item-content" : 'message-item-content'}>
                                            <div className="sender-name">
                                                {message.senderData.name}
                                            </div>
                                            <div className="message-content">
                                                <div className="message-payload">
                                                    {/* {
                                                        message.status === 'sending' && (<div className="pending"></div>)
                                                    } */}
                                                    {
                                                        message.status === 'fail' && (<div className="send-fail"></div>)
                                                    }
                                                    {
                                                        message.type === 'text' && (<div className="content-text">{message.payload.text}</div>)
                                                    }
                                                </div>
                                                {/* <div className={message.read ? 'message-read' : 'message-unread'}>
                                                    <div>{message.read ? '已读' : '未读'}</div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="chat-footer">
                <div className="input-box">
                    <textarea
                        maxLength={700}
                        autoComplete="off"
                        className="input-content"
                        value={text}
                        placeholder="输入内容"
                        onChange={(ele) => setText(ele.target.value)}></textarea>
                </div>
                <div className="send-box">
                    <button className="send-button" onClick={sendTextMessage}>发送</button>
                </div>
            </div>
        </div>
    )
}