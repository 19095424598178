import React, { useState } from "react";
import { Button, Form, Input, NavBar, Steps, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { postShopRegister } from "../axios/index";

export default function Register() {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [name, setName] = useState<string>('');
    const [address, setAddress] = useState('');
    const [wechat, setWechat] = useState('');
    const [alipay, setAlipay] = useState('');
    const handleShopRegister = () => {
        if(!phone) {
            Toast.show("手机号不能为空");
            return;
        }
        if(!password) {
            Toast.show("密码不能为空");
            return;
        }
        if(!passwordConfirm) {
            Toast.show("请输入二次密码");
            return;
        }
        if(password !== passwordConfirm) {
            Toast.show("请确认二次密码");
            return;
        }
        if(!name) {
            Toast.show("请输入店铺名");
            return;
        }
        if(!address) {
            Toast.show("请输入店铺地址");
            return;
        }
        postShopRegister({
            phone,
            password,
            name,
            address,
            wechat,
            alipay,
        }).then(({data}) => {
            if(data.status === "error"){
                Toast.show(data.errorMessage);
            } else {
                Toast.show("注册成功");
                navigate("/login");
            }
        })
    }
    return (
        <div>
            <NavBar
                back={null}>
                店铺入驻
            </NavBar>
            <Steps current={currentStep}>
                <Steps.Step title='注册账户' />
                <Steps.Step title='店铺认证' />
            </Steps>
            {
                currentStep === 0 && (
                    <div>
                        <Form layout='horizontal'>
                            <Form.Header>账号信息</Form.Header>
                            <Form.Item label='手机号' name='phone'>
                                <Input type='phone' placeholder='请输入手机号' clearable onChange={(data) => setPhone(data)}/>
                            </Form.Item>
                            <Form.Item label='密码' name='password'>
                                <Input placeholder='请输入密码' clearable type='password' onChange={(data) => {setPassword(data)}}/>
                                <span style={{ fontSize: "12px", color: "gray" }}>字母、数字或者英文符号，最短8位，区分大小写</span>
                            </Form.Item>
                            <Form.Item label='重复密码' name='passwordConfirm'>
                                <Input placeholder='请再次输入密码' clearable type='password' onChange={(data) => {setPasswordConfirm(data)}}/>
                            </Form.Item>
                        </Form>
                        <Form layout='horizontal'>
                            <Form.Header>店铺基本信息</Form.Header>
                            <Form.Item label='店名' name='name'>
                                <Input type='text' maxLength={8} placeholder='店铺名' clearable onChange={setName}/>
                                <span style={{ fontSize: "12px", color: "gray" }}>为您的店铺取个名字，8个汉字以内，确认填写后不可修改</span>
                            </Form.Item>
                            <Form.Item label='地址' name='address'>
                                <Input type='text' placeholder='店铺地址' clearable onChange={setAddress}/>
                            </Form.Item>
                        </Form>
                        <Form layout='horizontal'>
                            <Form.Header>店铺联系方式</Form.Header>
                            <Form.Item label='微信' name='wechat'>
                                <Input type='text' placeholder='微信号' clearable onChange={setWechat}/>
                            </Form.Item>
                            <Form.Item label='支付宝' name='alipay'>
                                <Input type='text' placeholder='支付宝账号' clearable onChange={setAlipay}/>
                            </Form.Item>
                        </Form>
                        <div style={{display: "flex", justifyContent: "space-around"}}>
                            <Button block color="primary" size='large' onClick={handleShopRegister}>立即注册</Button>
                        </div>
                    </div>
                )
            }
            
        </div>
    )
}