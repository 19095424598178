import { createSlice } from '@reduxjs/toolkit';
import { getOneDayEnd, getOneDayStart } from '../utils';

const initialState: {
  _id: string;
  name: string;
  self: boolean;
  shopId: string;
  shopAgentKey: string;
  enableClearData: Boolean,
  config: {
    [key: string]: any
  },
  ownerInfo: {
    phone: string,
    wechat: string,
    alipay: string,
    address: string,
  }
} = {
    _id: "",
    name: "",
    self: false,
    shopId: "",
    shopAgentKey: "",
    enableClearData: false,
    config: {
        football: {
            status: true,
            enableBuy: true,
            minAmount: 2,
            earlyCloseTime: 5 * 60 * 1000,
            enableGroupBuy: true,
            enableFollowBuy: true,
            enableBonusOptimization: true,
        },
        basketball: {
            status: true,
            enableBuy: true,
            minAmount: 2,
            earlyCloseTime: 5 * 60 * 1000,
            enableGroupBuy: true,
            enableFollowBuy: true,
            enableBonusOptimization: true,
        },
        beijing: {
            status: true,
            enableBuy: true,
            minAmount: 2,
            earlyCloseTime: 5 * 60 * 1000,
            enableGroupBuy: true,
            enableFollowBuy: true,
            enableBonusOptimization: true,
        },
        pickThree: {
            status: true,
            enableBuy: true,
            minAmount: 2,
            earlyCloseTime: 5 * 60 * 1000,
            enableGroupBuy: true,
            enableFollowBuy: true,
        },
    },
    ownerInfo: {
      phone: "",
      wechat: "",
      alipay: "",
      address: "",
    }
}

const initialStatistics: {
  precision: "day" | "month",
  startTime: number,
  endTime: number,
} = {
  precision: "day",
  startTime: getOneDayStart(Date.now()),
  endTime: getOneDayEnd(Date.now()),
}

export const shopSlice = createSlice({
  name: 'shop',
  initialState: {shop: initialState, statistics: initialStatistics },
  reducers: {
    set: (state, action) => {
      state.shop = action.payload;
    },
    clear: (state) => {
      state = {shop: initialState, statistics: initialStatistics};
    },
    updateConfig: (state, action) => {
        const { type, key, val } = action.payload;
        state.shop.config[type] && (state.shop.config[type][key] = val);
    },
    updateStatisticsTime: (state, action) => {
      state.statistics = action.payload;
    }
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { set, clear, updateConfig, updateStatisticsTime } = shopSlice.actions;

export default shopSlice.reducer;