import { Checkbox, Divider, NavBar, Space } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getManagerAgentUserList } from "../../axios";
import { getDay } from "../../utils";
export default function AgentUserList() {
    const params = useParams();
    const navigate = useNavigate();
    const agentId = useMemo(() => {
        return params.id
    }, [params.id]);
    const [userList, setUserList] = useState<any[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

    const userIdAll = useMemo(() => {
        return userList.map((user) => user._id);
    }, [userList])
    useEffect(() => {
        getManagerAgentUserList({agentId}).then(({data}) => {
            setUserList(data.result || []);
        });
    }, [agentId]);

    const handleSelectedAll = () => {
        setSelectedUsers(userIdAll);
    };
    const handleConfirm = () => {
        navigate("/manager/agent/listfortransfer", { state: { selectedUsers } });
    }
    const back = () => {
        navigate(-1);
    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            background: "#f4f4f4"
        }}>
            <NavBar onBack={back}>
                选择用户
            </NavBar>
            <div style={{ flex: "1 1" }}>
                <Checkbox.Group
                    value={selectedUsers}
                    onChange={v => {
                    setSelectedUsers(v as string[])
                    }}
                >
                    <Space direction='vertical' block style={{ padding: "10px" }}>
                        {
                            userList.map((user) => {
                                return (
                                    <div>
                                        <Checkbox key={user._id} value={user._id} block>
                                            <div>
                                                用户名：{user.remark || user.username} <br />
                                                手机号：{user.phone} <br />
                                                创建时间：{getDay(user.createTime)}
                                            </div>
                                        </Checkbox>
                                        <Divider />
                                    </div>
                                )
                            })
                        }
                    </Space>
                </Checkbox.Group>
            </div>
            <div style={{
                display: "flex",
                height: "40px",
                alignItems: "center",
                textAlign: "center"
            }}>
                <div style={{
                    flex: "1 1",
                    height: "100%",
                    lineHeight: "40px",
                    color: "blue",
                    background: "white"
                }}
                onClick={handleSelectedAll}>
                    全选
                </div>
                <div style={{
                    flex: "1 1",
                    height: "100%",
                    lineHeight: "40px",
                    background: "#8e8efd",
                    color: "white",
                }} onClick={handleConfirm}>
                    确定
                </div>
            </div>
        </div>
    )
}