import React, { useEffect, useMemo, useState } from "react";
import * as math from "mathjs";
import { cnNameMap }  from "../constant/cnNameMap";
import "./MatchBetDetail.css";
import { splitArrayByN, options2Str, splitArray } from "../utils";
import { PlayType } from "../constant/betPlayType";
import { calMatchBonus, calSPlimit, roundToTwo } from "../utils/MatchBonusCal.ts";
import { freeCombine, option2Num, mixedCombine2Free, mixedCombine } from "../constant/combine.ts";

interface Props {
    bet: any,
    detailVisible?: boolean,
}

export default function MatchBetDetail(props: Props) {
    const { bet, detailVisible = true } = props;
    const maxSP = useMemo(() => {
        return bet.maxSP?.toFixed(2) || "--";
    }, [bet])
    const betType = useMemo(() => {
        return bet.type;
    }, [bet])
    const items = useMemo(() => {
        return bet.items;
    }, [bet]);
    const isBonusOptimization = useMemo(() => {
        return bet.playType === PlayType.BonusOptimization || bet.isBonusOptimization;
    }, [bet]);
    const options = useMemo(() => {
        return bet.options || ''
    }, [bet]);
    const times = useMemo(() => {
        return bet.times;
    }, [bet]);
    const matchList = useMemo(() => {
        return bet.matchInfo?.matchList || [];
    }, [bet]);
    const matchNum = useMemo(() => {
        return bet.matchInfo?.matchNum || bet.matchInfo?.matchList?.length || 0;
    }, [bet]);
    const oddsForBonus = useMemo(() => {
        return bet.matchInfo?.oddsForBonus || {};
    }, [bet]);
    const resultForBonus = useMemo(() => {
        return bet.matchInfo?.resultForBonus || {};
    }, [bet])
    const [maxBonus, setMaxBonus] = useState<string>();
    useEffect(() => {
        if(isBonusOptimization) return;
        const optionArr = options.split('_');
        const matchIdList = matchList.map((match) => match._id);
        var calMaxAry = [];
        let ticketNum = 1;
        for (let i = 0, len = matchIdList.length; i < len; i++) {
            const matchId = matchIdList[i];
            const match = matchList[i];
            const item = items[matchId];
            const typeList = Object.keys(item);
            const ticket = typeList.length;
            ticketNum *= ticket;
            const [maxVal] = calMatchBonus(betType, item, oddsForBonus[matchId] || match?.odds)
            calMaxAry.push({ ticket, maxVal});
        }
        let maxBonus = 0;
        for(let option of optionArr) {
            let _optionArr: any[] = [];
            if(freeCombine.includes(option)) {
                _optionArr = [option];
            } else if (mixedCombine.includes(option)){
                _optionArr = mixedCombine2Free[option];
            }
            for(let _option of _optionArr) {
                const resultMaxArr = splitArrayByN(calMaxAry, option2Num[_option]);
                for (const result of resultMaxArr) {
                    let _ticketNum = 1;
                    let maxArr = [];
                    for (let data of result) {
                        _ticketNum *= data.ticket;
                        maxArr.push(data.maxVal);
                    }
                    const time = ticketNum / _ticketNum;
                    const resultSplitArr = splitArray(maxArr);
                    for (let resultSplit of resultSplitArr) {
                        const _bonux = resultSplit.reduce((a, b) => a * b, 1) * 2;
                        maxBonus += calSPlimit(option2Num[_option],roundToTwo(_bonux)) * time;
                    }
                }
            }
        }
        if(betType === 'beijing') {
            maxBonus *= 0.65;
        }
        setMaxBonus((maxBonus * times).toFixed(2));
    }, [matchList, items, options, isBonusOptimization, betType, times, oddsForBonus])
    return (
        <div className="match-table item">
            <div className="top">
                <span style={{ marginLeft: "7px", fontWeight: 900, marginRight: "5px" }}>投注信息</span>
                <div className="left">
                    <span>
                        <span style={{ display: "flex", flexFlow: "row", alignItems: "center", justifyContent: "center" }}>
                            <span className="item_value item_value1 item_class">{matchNum}场</span>
                            <span className="item_value item_value4 item_class"
                                style={{ border: "1px solid rgb(121, 208, 85)", margin: "0px" }}>
                                <span className="item_value item_value2"
                                    style={{ color: "rgb(121, 208, 85)", margin: "0px", padding: "0px 1px" }}>
                                    <span>{options2Str(options)}</span>
                                </span>
                            </span>
                            {
                                !isBonusOptimization && (<span className="item_value item_value4 item_class">{times}倍</span>)
                            }
                            {
                                isBonusOptimization && (
                                    <span className="item_value item_value5 item_class" style={{"margin":"0px 3px"}}><span>奖金优化</span></span>
                                )
                            }
                        </span>
                    </span>
                </div>
                <div className="right"></div>
            </div>
            <div className="container">
                <div style={{ display: "flex", width: "100%", height: "29px", textAlign: "center", lineHeight: "29px", backgroundColor: "rgb(223, 223, 223)", fontSize: "13px" }}>
                    <div style={{ width: "20%", border: "1px solid rgb(240, 243, 246)" }}>场次</div>
                    <div style={{ width: "20%", border: "1px solid rgb(240, 243, 246)" }}>对阵</div>
                    <div style={{ width: "20%", border: "1px solid rgb(240, 243, 246)" }}>玩法</div>
                    <div style={{ width: "20%", border: "1px solid rgb(240, 243, 246)" }}>选项(赔率)</div>
                    <div style={{ width: "20%", border: "1px solid rgb(240, 243, 246)" }}>彩果</div>
                </div>
                {
                    !detailVisible && (
                        <div className="noShowClass">
                            <div className="noShowClass-top">
                                <div className="noShowClass-top-icon">
                                    
                                </div>
                                <div className="noShowClass-top-text">截止后公开</div>
                            </div>
                            <div className="noShowClass-bottom">预计最高回报: { maxSP } 倍</div>
                        </div>
                    )
                }
                {
                    detailVisible && matchList?.length > 0 && matchList.map((match) => {
                        const matchId = match._id;
                        const itemArr = Object.entries(items[matchId]);
                        const matchOdds = match.odds;
                        const matchOddsForBonus = oddsForBonus[matchId];
                        const matchResultForBonus = resultForBonus[matchId] || match.result;
                        return (
                            <div className="" key={matchId}>
                                <div style={{ display: "flex", width: "100%", fontSize: "13px", minHeight: "34px" }}>
                                    <div style={{ display: "flex", width: "40%", textAlign: "center" }}>
                                        <div
                                            style={{ width: "100%", height: "100%", border: "1px solid rgb(240, 243, 246)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <div>{match.matchNumStr}</div>
                                        </div>
                                        <div
                                            style={{ width: "100%", height: "100%", border: "1px solid rgb(240, 243, 246)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <div>
                                                {
                                                    match.type === "basketball" ? (
                                                        <div className="title">
                                                            <div>
                                                                <span>[客]{match.awayTeamAbbName}</span>
                                                            </div>
                                                            <div>
                                                                {
                                                                    (match.finished && match.score) ? <span className="c_red1">{match.score}</span> : "VS"
                                                                }
                                                                {
                                                                    (match.finished && match.halfScore) && <span className="c_red1">({match.halfScore})</span>
                                                                }
                                                            </div>
                                                            <div>
                                                                <span>{match.homeTeamAbbName}[主]</span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="title">
                                                            <div>
                                                                <span>{match.homeTeamAbbName}</span>
                                                            </div>
                                                            <div>
                                                                {
                                                                    (match.finished && match.score) ? <span className="c_red1">{match.score}</span> : "VS"
                                                                }
                                                                {
                                                                    (match.finished && match.halfScore) && <span className="c_red1">({match.halfScore})</span>
                                                                }
                                                            </div>
                                                            <div>
                                                                <span>{match.awayTeamAbbName}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: "60%", height: "100%" }}>
                                        {
                                            itemArr.map(([type, item]) => {
                                                const key = `${matchId}-${type}`;
                                                const goalLine = matchOddsForBonus?.[type]?.goalLine || matchOdds?.[type]?.goalLine
                                                const goalLineNum = Number(goalLine);
                                                let result = matchResultForBonus?.[type];
                                                if (betType === 'basketball' && match.score && match.finished) {
                                                    if (type === 'hdc') {
                                                        const fullScore = match.score.split('-').map(Number);
                                                        if (fullScore[0] - goalLineNum > fullScore[1]) {
                                                            result = 'lose';
                                                        } else {
                                                            result = 'win';
                                                        }
                                                    }
                                                    if (type === 'hilo') {
                                                        const fullScore = match.score.split('-').map(Number);
                                                        if (fullScore[0] + fullScore[1] > goalLineNum) {
                                                            result = 'high';
                                                        } else {
                                                            result = 'low';
                                                        }
                                                    }
                                                }
                                                return (<div style={{ display: "flex", width: "100%", textAlign: "center" }}>
                                                    <div key={key} style={{ width: "100%", border: "1px solid rgb(240, 243, 246)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <div>
                                                            {cnNameMap[type]}
                                                            {
                                                                goalLineNum !== 0 && (<div>({goalLine})</div>)
                                                            }
                                                        </div>

                                                    </div>
                                                    <div
                                                        style={{ width: "100%", border: "1px solid rgb(240, 243, 246)", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "25px 0px" }}>
                                                        {
                                                            item?.target.map((target) => {
                                                                const itemKey = `${key}-${target}`;
                                                                const win = target === matchResultForBonus?.[type];
                                                                let odd = matchOddsForBonus?.[type]?.[target] || matchOdds?.[type]?.[target];
                                                                if(win && betType === "beijing") {
                                                                    odd = match?.result?.[`${type}Odds`] || matchOdds?.[type]?.[target];
                                                                }
                                                                return (
                                                                    <div key={itemKey}>
                                                                        <div className={target === matchResultForBonus?.[type] ? "c_red" : ""}>
                                                                            <div style={{ margin: "1px 0px" }}>
                                                                                {cnNameMap[`${type}_${target}`] || cnNameMap[target]}({ odd })
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div style={{ width: "100%", border: "1px solid rgb(240, 243, 246)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        {
                                                            match.cancel ? (<div>无效场次</div>) : ( <div>{cnNameMap[`${type}_${result}`] || cnNameMap[result]}</div>)
                                                        }
                                                    </div>
                                                </div>)
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="up"></div>
                                <div className="down"></div>
                            </div>
                        )
                    })
                }
                {
                    (!isBonusOptimization && detailVisible) && (
                        <div>
                            <div style={{ textAlign: "center", width: "100%", height: "43px", lineHeight: "43px" }}>
                                预计最高奖金：{maxBonus}元
                                <div data-v-e4d6d362="" className="u-icon u-icon--right" style={{ margin: "0px 10px" }}></div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}