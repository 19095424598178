import React, { useEffect, useState, useRef }  from 'react';
import OrderAudio from "./assets/shop/order.mp3";
import WinAudio from "./assets/shop/win.mp3";
import DrawAudio from "./assets/shop/draw.mp3";
import { Button, ErrorBlock, SafeArea, Toast } from 'antd-mobile'
import { StillnessProvider } from 'react-stillness-component';
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import StillnessOutLets from "./StillnessOutlets/index.js";
import { clear, set } from "./store/shopSlice.ts";

import './App.css';

import { HashRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from "./modules/login.tsx"

import GroupBuyItemDetail from "./modules/bet/GroupBuyDetail.tsx";
import ActionList from "./modules/user/ActionList.tsx"
import BetList from "./modules/user/BetListNew.tsx"
import ShareShop from './modules/share/ShareShop.tsx';
import ShopRegister from "./modules/share/ShopRegister.tsx";

import Home from './modules/bet/Home.tsx';
import BetDetail from "./modules/bet/BetDetail.tsx";
import PersonalCenter from "./modules/person/PersonalCenter.tsx"
import PayOrders from "./modules/person/PayOrders.tsx"
import PayOrdersHistory from "./modules/person/PayOrdersHistory.tsx";
import ManagerBetList from "./modules/bet/BetList.tsx";
import Statistics from "./modules/statistics/Statistics.tsx";
import StaticsDetailAction from "./modules/statistics/StaticsDetailAction.tsx";
import StaticsDetailBet from "./modules/statistics/StaticsDetailBet.tsx";
import StatisticsUserOrder from './modules/statistics/StatisticsUserOrder.tsx';
import AgentManagement from "./modules/agent/AgentManagement.tsx";
import SplitTickets from './modules/bet/SplitTickets.tsx';
import UserManage from "./modules/user/UserManage.tsx"
import UserInfo from "./modules/user/UserInfo.tsx"
import UserAgent from "./modules/agent/UserAgent.tsx"
import AgentUserList from './modules/agent/AgentUserList.tsx';
import AgentStatistics from './modules/agent/AgentStatistics.tsx';
import AgentListForTransfer from "./modules/agent/AgentListForTransfer.tsx";
import BetManagement from './modules/person/BetManagement.tsx';
import BetSetting from './modules/person/BetSetting.tsx';
import ShopActionList from './modules/person/ShopActionlist.tsx';
import StatisticsShopBalance from './modules/statistics/StatisticsShopBalance.tsx';
import StatisticsUserBalance from './modules/statistics/StatisticsUserBalance.tsx';
import StaticsUserRegister from './modules/statistics/StaticsUserRegister.tsx';
import DrawList from './modules/person/DrawList.tsx';
import DrawHistory from './modules/person/DrawHistory.tsx';
import ManagerPayPassword from "./modules/person/PayPassword.tsx";
import ChargeSetting from './modules/person/ChargeSetting.tsx';
import ManagerPassword from "./modules/person/Password.tsx";
import ManagerRegister from "./modules/Register.tsx";
import EmployeeManagement from './modules/employee/EmployeeManagement.tsx';
import EmployeeAdd from './modules/employee/EmployeeAdd.tsx';
import ShopSetting from './modules/person/ShopSetting.tsx';
import Converstions from './modules/chat/Conversations.tsx';
import PrivateChat from './modules/chat/PrivateChat.tsx';
import ChatGroup from './modules/chat/ChatGroup.tsx';
import ChatUserManage from './modules/chat/UserManagement.tsx';
import ChargeMoney from './modules/person/ChargeMoney.tsx';
import AutoDelete from './modules/person/AutoDelete.tsx';
import { getManagerNotification, getManagerShopBasicInfo } from './axios/index.js';
import { useDispatch } from 'react-redux';

function Layout() {
  return (
    <div>
      <StillnessOutLets />
    </div>
  )
}

function AppContainer() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [userInteracted, setUserInteracted] = useState(false);
  const OrderRef = useRef(null);
  const WinRef = useRef(null);
  const DrawRef = useRef(null);
  const timmerRef = useRef({timmer: null as any, time: Date.now()});
  useEffect(() => {
    getManagerShopBasicInfo({}).then(({data}) => {
      if(data.status === "error") {
          Toast.show(data.errorMessage);
      } else {
          const { shop } = data;
          dispatch(set(shop));
      }
  });
  }, [])
  useEffect(() => {
    if(userInteracted && !timmerRef.current.timmer) {
        timmerRef.current.timmer = setInterval(() => {
        getManagerNotification({lastTime: timmerRef.current.time}).then(({data}) => {
          if(data.status === 'error') {
            Toast.show(data.errorMessage);
          } else {
            timmerRef.current.time = Date.now();
            const { notificationType } = data;
            switch(notificationType) {
              case 'order': OrderRef.current?.play();break;
              case 'win': WinRef.current?.play();break;
              case 'draw': DrawRef.current?.play();break;
              default: break;
            }
          }
        });
      }, 10 * 1000);
    }
  }, [location, userInteracted])
  return (
    <div className="App" onClick={() => setUserInteracted(true)}>
        {/* <SafeArea position='top' /> */}
        <audio src={OrderAudio} ref={OrderRef} autoPlay={false}/>
        <audio src={WinAudio} ref={WinRef} autoPlay={false}/>
        <audio src={DrawAudio} ref={DrawRef} autoPlay={false}/>
        <div className='body'>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route path="manager/statistics/action" Component={StaticsDetailAction}></Route>
              <Route path="manager/statistics/bet" Component={StaticsDetailBet}></Route>
              <Route path="manager/payorders/history" Component={PayOrdersHistory}></Route>
              <Route path="manager/bet/list" Component={ManagerBetList}></Route>
              <Route path="manager/bet/detail/:id" Component={BetDetail}></Route>

              <Route path="manager/user" Component={UserManage}></Route>
              <Route path="manager/statistics/register" Component={StaticsUserRegister}></Route>
              <Route path="manager/user/info/:id" Component={UserInfo}></Route>
              <Route path="manager/user/betlist" Component={BetList}></Route>
            </Route>
            <Route path="/" >
              <Route path="" index Component={Home}></Route>
              <Route path="login" Component={Login}></Route>
              <Route path="share/shop" Component={ShareShop}></Route>
              <Route path="share/shop/register" Component={ShopRegister}></Route>
              <Route path="manager/register" Component={ManagerRegister}></Route>

              <Route path="manager/bet/splittickets/:id" Component={SplitTickets}></Route>
              <Route path="manager/groupbuy/itemdetail" Component={GroupBuyItemDetail}></Route>
              
              <Route path="manager/bet/management" Component={BetManagement}></Route>
              <Route path="manager/bet/setting" Component={BetSetting}></Route>

              <Route path="manager/user/agent/:id" Component={UserAgent}></Route>
              <Route path="manager/user/actionlist" Component={ActionList}></Route>
              

              <Route path="manager/person" Component={PersonalCenter}></Route>
              <Route path="manager/payorders" Component={PayOrders}></Route>
              <Route path="manager/shop/action" Component={ShopActionList}></Route>
              <Route path="manager/draw/list" Component={DrawList}></Route>
              <Route path="manager/draw/history" Component={DrawHistory}></Route>
              
              <Route path='manager/password' Component={ManagerPassword}></Route>
              <Route path='manager/paypassword' Component={ManagerPayPassword}></Route>
              <Route path='manager/employee/management' Component={EmployeeManagement}></Route>
              <Route path='manager/employee/add' Component={EmployeeAdd}></Route>
              <Route path="manager/shop/setting" Component={ShopSetting}></Route>
              <Route path="manager/shop/delete" Component={AutoDelete}></Route>

              
              <Route path="manager/shop/charge" Component={ChargeMoney}></Route>

              <Route path="manager/charge/setting" Component={ChargeSetting}></Route>
              
              <Route path="manager/statistics" Component={Statistics}></Route>
              <Route path="manager/statistics/user/order" Component={StatisticsUserOrder}></Route>
              <Route path="manager/statistics/shop/balance" Component={StatisticsShopBalance}></Route>
              <Route path="manager/statistics/user/balance" Component={StatisticsUserBalance}></Route>
              
              
              <Route path="manager/agent/management" Component={AgentManagement}></Route>
              <Route path='manager/agent/statistics/:id' Component={AgentStatistics}></Route>
              <Route path="manager/agent/userlist/:id" Component={AgentUserList}></Route>
              <Route path="manager/agent/listfortransfer" Component={AgentListForTransfer}></Route>

              <Route path="manager/chat/converstions" Component={Converstions}></Route>
              <Route path="manager/chat/private" Component={PrivateChat}></Route>
              <Route path="manager/chat/group" Component={ChatGroup}></Route>
              <Route path="manager/chat/user" Component={ChatUserManage}></Route>
              
            </Route>
            
          </Routes>
        </div>
      </div>
  )
}

function ErrorFallback() {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();

  return (
    <ErrorBlock
        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
        style={{
          '--image-height': '150px',
        }}
      >
        <Button color='primary' onClick={() => {navigate(-1); resetBoundary()}}>返回上一页</Button>
      </ErrorBlock>
  )
}

function App() {
  return (
    <Router>
        <StillnessProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AppContainer />
        </ErrorBoundary>
        </StillnessProvider>
      </Router>
  );
}

export default App;
