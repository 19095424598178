import React, { useMemo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, NavBar, Radio, Space, Toast } from "antd-mobile";
import { getManagerAgentList, postManagerAgentUserTransfer } from "../../axios/index.js";
import { getTime } from "../../utils/index.ts";
import defaultUser from "../../assets/images/user-2.jpg";

export default function UserAgent() {
    const location = useLocation();
    const navigate = useNavigate();
    const [agentList, setAgentList] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState("");
    const [currentAgent, setCurrentAgent] = useState();
    const user = useMemo(() => {
        return location.state?.user;
    }, [location.state?.user])
    const currentAgentId = useMemo(() => {
        return location.state?.agent?._id;
    }, [location.state?.agent]);
    useEffect(() => {
        getManagerAgentList({status: true}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage)
            } else {
                setCurrentAgent(data.result.find((val) => val.agent._id === currentAgentId))
                setAgentList(data.result.filter((val) => val.agent._id != currentAgentId));
            }
        });
    }, []);

    const handleUserTransfer = () => {
        if(!selectedAgent) {
            Toast.show("请选择一个代理");
        }
        postManagerAgentUserTransfer({
            agentId: selectedAgent, users: [user._id],
        }).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                navigate(-1);
            }
        })
    }
    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
            <NavBar onBack={() => navigate(-1)}>
                代理转移
            </NavBar>
            {
                currentAgent && (
                    <div style={{ flex: "0 1" }}>
                        <div style={{ padding: "10px", backgroundColor: "#d5d5d5" }}>他的代理</div>
                        <div style={{ padding: "12px" }}>
                            <div style={{ display: 'flex' }}>
                                <Avatar src={currentAgent?.agent.avatar || defaultUser} />
                                <div style={{ marginLeft: "10px" }}>
                                    <div style={{ fontSize: "14px", fontWeight: "bolder" }}>{currentAgent?.agent.remark || currentAgent?.agent.username}</div>
                                    <div className="userDesc">{`创建时间: ${getTime(currentAgent?.createTime)}`}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div style={{ flex: "1 1" }}>
                <div style={{ padding: "10px", backgroundColor: "#d5d5d5" }}>转移至</div>
                <div style={{ padding: '12px' }}>
                    {
                        agentList.length > 0 ? (
                            <Radio.Group onChange={(agentId: any) => setSelectedAgent(agentId)}>
                                <Space direction='vertical' block>
                                    {
                                        agentList?.map(({ agent, createTime }) => {
                                            return (
                                            <Radio value={agent._id as string} block>
                                                <div style={{ display: 'flex' }}>
                                                    <Avatar src={agent.avatar || defaultUser} />
                                                    <div style={{ marginLeft: "10px" }}>
                                                        <div style={{ fontSize: "14px", fontWeight: "bolder" }}>{agent.remark || agent.username}</div>
                                                        <div className="userDesc">{`创建时间: ${getTime(createTime)}`}</div>
                                                    </div>
                                                </div>
                                            </Radio>
                                            )
                                        })
                                    }
                                </Space>
                            </Radio.Group>
                        ) : (
                            <div>没有其他代理</div>
                        )
                    }
                </div>
            </div>
            <Button color="primary" onClick={handleUserTransfer}>确认</Button>
        </div>
        
    )
}