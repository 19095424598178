import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { NavBar, Space, Toast, SearchBar, Button, Popup, Calendar, AutoCenter, InfiniteScroll } from "antd-mobile";
import { FileOutline } from "antd-mobile-icons";
import { getManagerBetList } from "../../axios/index.js";
import { PlayType } from "../../constant/betPlayType.ts";
import { cnNameMap, status2CN } from "../../constant/cnNameMap.ts";
import { getTime } from "../../utils/index.ts";
import FootballPNG from "../../assets/407.png";
import BasketballPNG from "../../assets/406.png";
import BeijingPNG from "../../assets/405.png";
import PickThreePNG from "../../assets/202.png";
import ChampionPNG from "../../assets/champion.png";

const betTypes = {
    all: "football,basketball,pickThree,beijing,champion",
    football: "football",
    basketball: "basketball",
    pickThree: "pickThree",
    beijing: "beijing",
};
const betStatus = {
    all: "0,1,2,3,4,5,6"
}

function PopUpModal(props: {
    status: string,
    type: string,
    onChange: (status: string, type: string) => void
}) {
    const {status: _status, type: _type, onChange} = props;
    const [type, setType] = useState(props.type || betTypes.all);
    const [status, setStatus] = useState(props.status || betStatus.all)
    const [ calendarVisible, setCalendarVisible] = useState(false);

    return (
        <div style={{ padding: "20px" }}>
            <h3>彩种选择</h3>
            <Space wrap>
                <Button size='mini' color={type === betTypes.all ? "primary" : "default"} onClick={() => setType(betTypes.all)}>全部彩种</Button>
                <Button size='mini' color={type === betTypes.football ? "primary" : "default"} onClick={() => setType(betTypes.football)}>竞彩足球</Button>
                <Button size='mini' color={type === betTypes.basketball ? "primary" : "default"} onClick={() => setType(betTypes.basketball)}>竞彩篮球</Button>
                <Button size='mini' color={type === betTypes.beijing ? "primary" : "default"} onClick={() => setType(betTypes.beijing)}>北京单场</Button>
                <Button size='mini' color={type === betTypes.pickThree ? "primary" : "default"} onClick={() => setType(betTypes.pickThree)}>排列三</Button>
            </Space>
            <h3>状态选择</h3>
            <Space wrap>
                <Button size='mini' color={status === betStatus.all ? "primary" : 'default'} onClick={() => setStatus(betStatus.all)}>全部状态</Button>
                <Button size='mini' color={status === "0" ? "primary" : 'default'} onClick={() => setStatus("0")}>未接单</Button>
                <Button size='mini' color={status === "1" ? "primary" : 'default'} onClick={() => setStatus("1")}>出票中</Button>
                <Button size='mini' color={status === "2" ? "primary" : 'default'} onClick={() => setStatus("2")}>已出票</Button>
                <Button size='mini' color={status === "3" ? "primary" : 'default'} onClick={() => setStatus("3")}>未中奖</Button>
                <Button size='mini' color={status === "4" ? "primary" : 'default'} onClick={() => setStatus("4")}>已中奖</Button>
                <Button size='mini' color={status === "5" ? "primary" : 'default'} onClick={() => setStatus("5")}>已派奖</Button>
                <Button size='mini' color={status === "6" ? "primary" : 'default'} onClick={() => setStatus("6")}>已撤单</Button>
                {/* <Button size='mini' color={status === betStatus.all ? "primary" : 'default'} onClick={() => setStatus(betTypes.all)}>已取票</Button> */}
            </Space>
            {/* <h3>时间选择</h3>
            <Space>
                <Button size='mini'>全部</Button>
                <Button size='mini' onClick={() => setCalendarVisible(true)}>选择区间</Button>
            </Space> */}
            <div style={{display: "flex", flexDirection: "row"}}>
                <Button>取消</Button>
                <Button color="primary" onClick={() => onChange(status, type)}>确认</Button>
            </div>
            <Popup
                visible={calendarVisible}
                onMaskClick={() => {
                    setCalendarVisible(false)
                }}
                onClose={() => {
                    setCalendarVisible(false)
                }}
                >
                <Calendar
                    selectionMode='range'
                    onChange={val => {
                        console.log(val)
                    }}
                />
                <AutoCenter>
                    <Button color="primary" style={{width: "200px"}} >确定</Button>
                </AutoCenter>
            </Popup>
        </div>
    )
}


export default function OrderList() {
    const location = useLocation();
    const navigate = useNavigate();
    const betListRef = useRef(null);
    const [scrollTop, setScrollTop] = useState(0);
    const [ filterVisible, setFilterVisible] = useState(false);
    const [type, setType] = useState(betTypes.all);
    const [status, setStatus] = useState(betStatus.all);
    const pagenation = useRef({ pageNum: 0, loading: false });
    const [hasMore, setHasMore] = useState(true);
    const [betList, setBetList] = useState<any>([]);
    const userId = useMemo(() => {
        return location.state?.id;
    }, [location.state?.id])

    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            type,
            status,
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        pagenation.current.pageNum++;
        if (userId) {
            query["userId"] = userId;
        }
        try {
            const { data } = await getManagerBetList(query);
            setBetList([...betList, ...data.betList]);
            setHasMore(data.betList.length === 10);
        } catch (e) {
            Toast.show("获取彩票失败");
        }
        pagenation.current.loading = false;
    }
    useEffect(() => {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        setBetList([]);
        pagenation.current.pageNum = 0;
        let query: any = {
            type,
            status,
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        if (userId) {
            query["userId"] = userId;
        }
        getManagerBetList(query).then(({ data }: any) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                pagenation.current.pageNum += 1;
                setBetList(data.betList);
                setHasMore(data.betList.length === 10);
            }
        }).finally(() => {
            pagenation.current.loading = false;
        })
    }, [status, type, userId]);
    const Filter = (
        <div>
          <Space style={{ '--gap': '16px' }}>
            <FileOutline fontSize={"24px"} onClick={() => {setFilterVisible(true)}}/>
          </Space>
        </div>
    )
    const back = () => {
        navigate(-1);
    }
    const handleBetClick = (id: string) => {
        setScrollTop(betListRef.current?.scrollTop || 0);
        navigate(`/manager/bet/detail/${id}`)
    }
    useEffect(() => {
        if(location.pathname === "/manager/bet/list") {
            if(betListRef) {
                setTimeout(() => {
                    betListRef.current.scrollTop = scrollTop
                }, 1)
            }
        }
    }, [location.pathname, scrollTop]);
    return (
        <div style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
        }}>
            <NavBar right={Filter} onBack={back}>
                订单查询
            </NavBar>
            <SearchBar placeholder='请输入订单号' />
            <Popup
                visible={filterVisible}
                onMaskClick={() => {
                    setFilterVisible(false);
                }}
                position='right'
                bodyStyle={{ width: '60vw' }}
                >
                    <PopUpModal status={status} type={type} onChange={(status, type) => { setStatus(status); setType(type); setFilterVisible(false) }}/>                
            </Popup>
            <div style={{ flex: "1 1", overflow: "auto", backgroundColor: "whitesmoke" }} ref={betListRef}>
                {
                    betList.map((bet) => {
                        const username = bet.userId.remark || bet.userId.username;
                        const userBuyTime = bet.userBuyTime;
                        return (
                            <div key={bet._id} style={{ display: "flex", padding: "10px", fontSize: "13px", backgroundColor: "white", borderRadius: "10px", margin: "10px" }}
                                onClick={() => handleBetClick(bet._id)}>
                                <div style={{ marginRight: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {
                                        (bet.type === "football") && (<img src={FootballPNG} alt="" style={{ width: "40px" }}/>)
                                    }
                                    {
                                        (bet.type === "beijing") && (<img src={BeijingPNG} alt="" style={{ width: "40px" }}/>)
                                    }
                                    {
                                        (bet.type === "basketball") && (<img src={BasketballPNG} alt="" style={{ width: "40px" }}/>)
                                    }
                                    {
                                        (bet.type === "pickThree") && (<img src={PickThreePNG} alt="" style={{ width: "40px" }}/>)
                                    }
                                    {
                                        (bet.type === "champion") && (<img src={ChampionPNG} alt="" style={{ width: "40px" }}/>)
                                    }

                                </div>
                                <div style={{ flex: 1 }}>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        {cnNameMap[bet.type as string]} {cnNameMap[bet.subType]}
                                        {
                                            bet.playType === PlayType.PublishFollowBuy && (
                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>发单</span>
                                            )
                                        }
                                        {
                                            bet.playType === PlayType.FollowBuy && (
                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>跟单</span>
                                            )
                                        }
                                        {
                                            bet.playType === PlayType.GroupBuy && (
                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>合买</span>
                                            )
                                        }
                                        {
                                            bet.playType === PlayType.ChaseNum && (
                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>追号</span>
                                            )
                                        }
                                        {
                                            bet.playType === PlayType.BonusOptimization && (
                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>奖金优化</span>
                                            )
                                        }
                                        {
                                            bet.playType === PlayType.Normal && (
                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>自购</span>
                                            )
                                        }
                                    </span> <br />
                                    <span>下单用户：{ username }</span> <br />
                                    <span>下单时间：{ getTime(userBuyTime) }</span> <br />
                                    {
                                        ["4", "5"].includes(bet.status) && (
                                            <span style={{ color: "red" }}>奖金: {bet.bonus?.toFixed(2)}元</span>
                                        )
                                    }
                                    {/* <span style={{ color: "red" }}>派奖金额：{bet.bonus?.toFixed(2)}元 </span> <span style={{ color: "#b8b871", border: "solid 1px #b8b871", borderRadius: "5px", padding: "0 5px" }} onClick={() => navigate(`/manager/bet/detail/${bet._id}`)}>修改奖金</span> <br /> */}
                                </div>
                                <div>
                                    <span style={{ color: "red" }}>
                                        {bet.spend}元
                                    </span> <br />
                                    <span>
                                        {status2CN[bet.status]}
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
            </div>
        </div>
    )
}