import { Avatar, InfiniteScroll, NavBar, SafeArea, SearchBar, Tabs, Toast } from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getManagerUserList } from "../../axios/index.js";
import Footer from "../../components/ManagerFooter.tsx";
import { getDay, getOneDayEnd, getOneDayStart, getTime } from "../../utils/index.ts";
import { SearchOutline } from "antd-mobile-icons";
import defaultUser from "../../assets/default_avatar.png";

export default function StaticsUserRegister() {
    const navigate = useNavigate();
    const location = useLocation();
    const userListRef = useRef(null);
    const now = useMemo(() => Date.now(), []);
    const showTimeStr = useMemo(() => location.state?.showTimeStr || getDay(now), [location.state?.showTimeStr, now]);
    const startTime = useMemo(() => location.state?.startTime || getOneDayStart(now), [location.state?.startTime, now]);
    const endTime = useMemo(() => location.state?.endTime || getOneDayEnd(now), [location.state?.endTime, now]);
    const [userList, setUserList] = useState<any[]>([]);
    const [scrollTop, setScrollTop] = useState(0);
    const pagenation = useRef({ pageNum: 0, loading: false });
    const [hasMore, setHasMore] = useState(true);
    const [toUserInfo, setToUserInfo] = useState(false);
    
    async function loadMore() {
        if (pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
            startTime,
            endTime,
        };
        getManagerUserList(query).then(({ data }) => {
            if (data.status === "error") {
                Toast.show(data.errorMessage);
                setHasMore(false);
            } else {
                pagenation.current.pageNum++;
                setUserList([...userList, ...data.userList]);
                setHasMore(data.userList.length === 10);
            }
        }).finally(() => {
            pagenation.current.loading = false;;
        })
    }
    const back = () => {
        setToUserInfo(false);
        navigate(-1);
    }
    const handleUserClick = (id: string) => {
        setToUserInfo(true);
        setScrollTop(userListRef.current?.scrollTop || 0);
        navigate(`/manager/user/info/${id}`)
    }
    useEffect(() => {
        if (location.pathname === "/manager/statistics/register") {
            if(!toUserInfo) {
                setUserList([]);
                setHasMore(true);
                pagenation.current.pageNum = 0;
            }
            if (userListRef && userListRef.current) {
                setTimeout(() => {
                    userListRef.current.scrollTop = scrollTop
                }, 1)
            }
        }
    }, [location.pathname, scrollTop]);
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <NavBar onBack={back}>
                {showTimeStr}用户注册
            </NavBar>
            <div style={{ display: "flex", flexDirection: "column", flex: '1', background: "#f0f0f0", overflow: "auto" }}>
                <div style={{ flex: "1 1", overflowY: "auto", padding: "0 10px" }} ref={userListRef}>
                    {
                        userList && userList.map((user) => (
                            <div
                                key={user._id}
                                style={{ display: "flex", gap: "10px", padding: "10px", margin: '10px 0', borderRadius: "10px", background: "white" }}
                                onClick={() => handleUserClick(user._id)}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Avatar src={user.avatar || defaultUser} />
                                </div>
                                <div style={{ flex: "1 1" }}>
                                    {user.remark || user.username} <br />
                                    <span style={{ color: "gray", fontSize: "13px" }}>{getTime(user.createTime)}</span>
                                </div>
                                <div>
                                    {user.balance?.toFixed(2)}
                                </div>
                            </div>
                        ))
                    }
                    <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />
                    <SafeArea position='bottom' />
                </div>
            </div>
            <div style={{
                position: "fixed",
                bottom: 0,
                width: "100%",
            }}>
                <Footer />
            </div>
        </div>
    )
}