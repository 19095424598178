import React, { useState, useEffect, useMemo, useCallback, useRef  } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GoEasy from 'goeasy';
import { getGoEasyClient, connectGoEasyClient } from "../../utils/getIm"; 
import "./PrivateChat.css";
import PendingGIF from "../../assets/chat/images/pending.gif";
import { NavBar, Toast } from "antd-mobile";

const MyAvatar = "https://tc-lottery.oss-cn-guangzhou.aliyuncs.com/chat-avatar/Avatar-1.png";
const ShopAvatar = "https://tc-lottery.oss-cn-guangzhou.aliyuncs.com/chat-avatar/Avatar-3.png";
export default function PrivateChat() {
    const location = useLocation();
    const navigate = useNavigate();
    const shop = useSelector((state) => state.shop.shop);
    const loadHistoryPosition = useRef({ toBottom: true });
    const goEasy = useMemo(() => getGoEasyClient(), []);
    const scrollViewRef = useRef(null);
    const messageListRef = useRef(null);
    const [text, setText] = useState("");
    const user = useMemo(() => ({
        id: location.state.id,
        name: location.state.name,
        avatar: location.state.avatar
    }), [location])
    const to = useMemo(() => ({
        type: GoEasy.IM_SCENE.PRIVATE,
        id: location.state.id,
        data: { name: location.state.name, avatar: location.state.avatar}
    }), [location])
    const [historyMessages, setHistoryMessages] = useState<any[]>([]);
    const [historyLoading, setHistoryLoading] = useState(true);
    const [historyAllLoaded, setHistoryAllLoaded] = useState(false);

    const markPrivateMessageAsRead = useCallback(() => {
        goEasy.im.markMessageAsRead({
            id: to.id,
            type: to.type,
            onSuccess: function () {
              console.log('标记私聊已读成功');
            },
            onFailed: function (error: any) {
              console.log('标记私聊已读失败', error);
            },
          });
    }, [goEasy.im, to.id, to.type]);

    const scrollToBottom = () => {
        if(scrollViewRef && messageListRef) {
            scrollViewRef.current.scrollTop = messageListRef.current.scrollHeight
        }
    }
    
    const loadHistoryMessage = useCallback(function(toBottom: any) {
        loadHistoryPosition.current.toBottom = toBottom;
        setHistoryLoading(true);
        //历史消息
        let lastMessageTimeStamp:any = null;
        let lastMessage = historyMessages[0];
        if (lastMessage) {
          lastMessageTimeStamp = lastMessage.timestamp;
        }
        goEasy.im.history({
          userId: user.id,
          lastTimestamp: lastMessageTimeStamp,
          limit: 10,
          onSuccess: (result: { content: any; }) => {
            setHistoryLoading(false);
            let messages = result.content;
            if (messages.length === 0) {
                setHistoryAllLoaded(true);
            } else {
              if (lastMessageTimeStamp) {
                setHistoryMessages(messages.concat(historyMessages));
              } else {
                setHistoryMessages(messages);
              }
              if (messages.length < 10) {
                setHistoryAllLoaded(true);
              }
              if (toBottom) {
                  scrollToBottom();
                  markPrivateMessageAsRead();
              }
            }
          },
          onFailed: (error: { code: string; content: string; }) => {
            //获取失败
            setHistoryLoading(false);
            console.log('获取历史消息失败, code:' + error.code + ',错误信息:' + error.content);
          },
        });
      }, [goEasy.im, historyMessages, markPrivateMessageAsRead, user.id])
    
    const onReceivedPrivateMessage = useCallback((message: { senderId: any; }) => {
        if (message.senderId === user.id) {
            setHistoryMessages([...historyMessages, message])
            markPrivateMessageAsRead();
          }
    }, [historyMessages, markPrivateMessageAsRead, user.id]);

    const sendMessage = (message: any) => {
        setHistoryMessages([...historyMessages, message]);
        loadHistoryPosition.current.toBottom = true;
        goEasy.im.sendMessage({
          message: message,
          onSuccess: (message: any) => {
            scrollToBottom();
            console.log('发送成功', message);
          },
          onFailed: function (error: any) {
            scrollToBottom();
            Toast.show('发送失败:');
          }
        });
      }

    const sendTextMessage = () => {
        if (!text.trim()) {
          Toast.show("输入为空");
          return
        }
        goEasy.im.createTextMessage({
          text: text,
          to: to,
          onSuccess: (message: any) => {
            sendMessage(message);
            setText("");
          },
          onFailed: (err: any) => {
            console.log("创建消息err:", err);
          }
        });
      }
    
    
    useEffect(() => {
        const initIM = async () => {
            await connectGoEasyClient(goEasy, shop);
            loadHistoryMessage(true);
            goEasy.im.on(GoEasy.IM_EVENT.PRIVATE_MESSAGE_RECEIVED, onReceivedPrivateMessage);
        }
        initIM();
    }, [goEasy, loadHistoryMessage, onReceivedPrivateMessage, shop]);

    useEffect(() => {
      if(loadHistoryPosition.current.toBottom) {
        scrollToBottom();
        markPrivateMessageAsRead();
      }
    }, [historyMessages, markPrivateMessageAsRead])
    return (
        <div className="chat-container">
            <NavBar onBack={() => navigate(-1)}>
              { user.name }
            </NavBar>
            {/* <div className="chat-title">
                <img src={user.avatar} className="chat-avatar" alt="店主"/>
                <div className="chat-name">{ user.name }</div>
            </div> */}
            <div className="chat-main" ref={scrollViewRef}>
                <div className="message-list" ref={messageListRef}>
                    {
                        historyLoading ? (
                            <div className="history-loading">
                            <img src={PendingGIF} alt="加载中..."/>
                            </div>
                        ) : (
                            <div className={historyAllLoaded ? 'history-loaded':'load'} onClick={() => loadHistoryMessage(false)}>
                            { historyAllLoaded ? '已经没有更多的历史消息' : '获取历史消息' }
                            </div>
                        )
                    }
                    {
                        historyMessages && historyMessages.map((message, index) => {
                            const selfMessage = message.senderId === shop._id;
                            return (
                                <div>
                                    {/* <div className="time-tips">{ renderMessageDate(message, index) }</div> */}
                                    <div className="message-item">
                                        <div className={ selfMessage ? "self message-item-content": 'message-item-content' }>
                                            <div className="sender-info">
                                                {
                                                    selfMessage ? (
                                                        <img src={ShopAvatar} className="sender-avatar" alt="用户"/>
                                                    ) : (
                                                        <img src={user.avatar} className="sender-avatar" alt="店主"/>
                                                    )
                                                }
                                            </div>
                                            <div className="message-content">
                                                <div className="message-payload">
                                                    {/* {
                                                        message.status === 'sending' && (<div className="pending"></div>)
                                                    } */}
                                                    {
                                                        message.status === 'fail' && (<div className="send-fail"></div>)
                                                    }
                                                    {
                                                        message.type === 'text' && (<div className="content-text">{message.payload.text}</div>)
                                                    }
                                                </div>
                                                <div className={message.read ?'message-read':'message-unread'}>
                                                    <div>{ message.read ? '已读' : '未读' }</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="chat-footer">
                <div className="input-box">
                  <textarea
                        maxLength={700}
                        autoComplete="off"
                        className="input-content"
                        value={text}
                        placeholder="输入内容"
                        onChange={(ele) => setText(ele.target.value)}></textarea>
                </div>
                <div className="send-box">
                <button className="send-button" onClick={sendTextMessage}>发送</button>
                </div>
            </div>
        </div>
    )
}