import React, { useMemo, useState } from "react";
import { Input, List, Mask, NavBar, Switch, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { postShopOwnerInfo } from "../../axios";

export default function ShopSetting() {
    const navigate = useNavigate();
    const shop = useSelector((state) => state.shop?.shop || {});
    const enableClearData = useMemo(() => shop.enableClearData, [shop]);
    const [realName, setRealName] = useState(shop.ownerInfo.realName);
    const [address, setAddress] = useState(shop.ownerInfo.address);
    const [alipay, setAlipay] = useState(shop.ownerInfo.alipay);
    const [wechat, setWechat] = useState(shop.ownerInfo.wechat);
    const [realNameEditVisible, setRealNameEditVisible] = useState(false);
    const [addressEditVisible, setAddressEditVisible] = useState(false);
    const [wechatEditVisible, setWechatEditVisible] = useState(false);
    const [alipayEditVisible, setAlipayEditVisible] = useState(false);

    const handleRealNameEditCancel = () => {
        setRealName(shop.ownerInfo.realName);
        setRealNameEditVisible(false);
    };
    const handleRealNameEdit = () => {
        setRealNameEditVisible(false);
        postShopOwnerInfo({key: 'realName', val: realName}).then(({data}) => {
            if (data.status === 'error') {
                Toast.show(data.errorMessage);
            }
        })
    }
    const handleAddressEditCancel = () => {
        setAddress(shop.ownerInfo.address);
        setAddressEditVisible(false);
    };
    const handleAddressEdit = () => {
        setAddressEditVisible(false);
        postShopOwnerInfo({key: 'address', val: address}).then(({data}) => {
            if (data.status === 'error') {
                Toast.show(data.errorMessage);
            }
        })
    }
    const handleWechatEditCancel = () => {
        setWechat(shop.ownerInfo.wechat);
        setWechatEditVisible(false);
    };
    const handleWechatEdit = () => {
        setWechatEditVisible(false);
        postShopOwnerInfo({key: 'wechat', val: wechat}).then(({data}) => {
            if (data.status === 'error') {
                Toast.show(data.errorMessage);
            }
        })
    }
    const handleAlipayEditCancel = () => {
        setAlipay(shop.ownerInfo.alipay);
        setAlipayEditVisible(false);
    };
    const handleAlipayEdit = () => {
        setAlipayEditVisible(false);
        postShopOwnerInfo({key: 'alipay', val: alipay}).then(({data}) => {
            if (data.status === 'error') {
                Toast.show(data.errorMessage);
            }
        })
    }
    return (
        <div>
            <NavBar
                onBack={() => navigate(-1)}>
                店铺信息
            </NavBar>
            <List>
                <List.Item extra={shop.name}>
                    店铺名
                </List.Item>
                <List.Item extra={address} onClick={() => setAddressEditVisible(true)} clickable>
                    店铺地址
                </List.Item>
                <List.Item extra={shop.ownerInfo.phone}>
                    手机号码
                </List.Item>
                <List.Item extra={wechat} onClick={() => setWechatEditVisible(true)} clickable>
                    微信号
                </List.Item>
                <List.Item extra={realName}
                    onClick={() => setRealNameEditVisible(true)}
                    clickable
                    description="与支付宝账号对应，否则用户线上充值后无法正常转款到您的支付宝">
                    支付宝实名
                </List.Item>
                <List.Item extra={alipay} onClick={() => setAlipayEditVisible(true)} clickable>
                    支付宝账号
                </List.Item>
                <List.Item clickable onClick={() => navigate("/manager/password")}>
                    登录密码
                </List.Item>
                <List.Item clickable onClick={() => navigate("/manager/paypassword")}>
                    支付密码
                </List.Item>
                <List.Item clickable onClick={() => navigate("/manager/shop/delete")}>
                            数据清理
                        </List.Item>
                {
                    enableClearData && (
                        <List.Item clickable onClick={() => navigate("/manager/shop/delete")}>
                            数据清理
                        </List.Item>
                    )
                }
            </List>
            <Mask visible={realNameEditVisible}>
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        width: "60vh",
                        height: "auto",
                        backgroundColor: "white",
                        padding: "10px"
                    }}>
                        <div style={{ textAlign: "center" }}>设置店主名(用于支付宝转账)</div>
                        <div style={{
                            margin: "10px 0"
                        }}><Input placeholder="请输入店主名" value={realName} onChange={setRealName}/></div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around"
                        }}>
                            <div
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={handleRealNameEditCancel}>取消</div>
                            <div 
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={handleRealNameEdit}>确认</div>
                        </div>
                    </div>
                </div>
            </Mask>
            <Mask visible={addressEditVisible}>
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        width: "60vh",
                        height: "auto",
                        backgroundColor: "white",
                        padding: "10px"
                    }}>
                        <div style={{ textAlign: "center" }}>设置店铺地址</div>
                        <div style={{
                            margin: "10px 0"
                        }}><Input placeholder="请输入店铺地址" value={address} onChange={setAddress}/></div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around"
                        }}>
                            <div
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={handleAddressEditCancel}>取消</div>
                            <div 
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={handleAddressEdit}>确认</div>
                        </div>
                    </div>
                </div>
            </Mask>
            <Mask visible={wechatEditVisible}>
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        width: "60vh",
                        height: "auto",
                        backgroundColor: "white",
                        padding: "10px"
                    }}>
                        <div style={{ textAlign: "center" }}>设置微信号</div>
                        <div style={{
                            margin: "10px 0"
                        }}><Input placeholder="请输入微信号" value={wechat} onChange={setWechat}/></div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around"
                        }}>
                            <div
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={handleWechatEditCancel}>取消</div>
                            <div 
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={handleWechatEdit}>确认</div>
                        </div>
                    </div>
                </div>
            </Mask>
            <Mask visible={alipayEditVisible}>
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        width: "60vh",
                        height: "auto",
                        backgroundColor: "white",
                        padding: "10px"
                    }}>
                        <div style={{ textAlign: "center" }}>设置支付宝账号</div>
                        <div style={{
                            margin: "10px 0"
                        }}><Input placeholder="请输入支付宝账号" value={alipay} onChange={setAlipay}/></div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around"
                        }}>
                            <div
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={handleAlipayEditCancel}>取消</div>
                            <div 
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={handleAlipayEdit}>确认</div>
                        </div>
                    </div>
                </div>
            </Mask>
        </div>
    )
}