import React, { useEffect, useState } from "react";
import { Avatar, Button, Dialog, NavBar, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { getShopEmployeeList, postShopEmployeeDelete } from "../../axios";
import { getTime } from "../../utils";
import defaultUser from "../../assets/default_avatar.png";

export default function EmployeeManagement() {
    const navigate = useNavigate();
    const [employeeList, setEmployeeList] = useState<any[]>([]);
    useEffect(() => {
        getShopEmployeeList({}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setEmployeeList(data.result);
            }
        })
    }, []);
    const handleEmployeeDelete = (employeeId: string, username: string) => {
        Dialog.show({
            content: `确认删除员工${username}`,
            closeOnAction: true,
            onAction: (action) => {
                if(action.key === "delete") {
                    postShopEmployeeDelete({ employeeId }).then(({data}) => {
                        if(data.status === "error") {
                            Toast.show(data.errorMessage);
                        } else {
                            setEmployeeList(employeeList.filter((employee) => employee._id !== employeeId));
                        }
                    });
                }
            },
            actions: [
            [
                {
                key: 'cancel',
                text: '取消',
                },
                {
                key: 'delete',
                text: '删除',
                bold: true,
                danger: true,
                },
            ],
            ],
        });
    }
    return (
        <div>
            <NavBar onBack={() => navigate(-1)}>
                员工管理
            </NavBar>
            <div>
                <div>
                    {
                        employeeList && employeeList.map((employee) => {
                            return (
                                <div 
                                    key={employee._id}
                                    style={{ display: "flex", gap: "10px", margin: "10px", padding: "10px", borderRadius: "10px", background: "white" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Avatar src={defaultUser} style={{ "--size": "24px" }}/>
                                    </div>
                                    <div style={{ flex: "1 1" }}>
                                        {employee.username} <br />
                                        <span style={{ color: "gray", fontSize: "13px" }}>{ getTime(employee.createTime) }</span>
                                    </div>
                                    <div>
                                        <Button onClick={() => handleEmployeeDelete(employee._id, employee.username)}>删除</Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    <Button block onClick={() => navigate("/manager/employee/add")}>+添加新员工</Button>
                </div>
            </div>
        </div>
    )
}