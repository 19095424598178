import { Collapse, List, NavBar, Space, Image, Switch, Toast, ImageUploader, ImageUploadItem, Button } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getManagerChargeInfo, postManagerChargeUpdate, postManagerUpdateQrCode } from "../../axios/index.js";

export default function ChargeSetting() {
    const navigate = useNavigate();
    const [chargeInfo, setChargeInfo] = useState<{alipay: boolean, qrcode: []}>({alipay: false, qrcode: []});
    const [alipayStatus, setAlipayStatus] = useState(false);
    const [fileList, setFileList] = useState<ImageUploadItem[]>([]);
    const [editVisible, setEditVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleFileUpload = async (file: File) => {
        try {
            const { data } = await postManagerUpdateQrCode({ image: file });
            if(data.status === "error") {
                Toast.show(data.errorMessage);
                return { url: null }
            } else {
                return { url: data.result.url }
            }
        } catch(e) {
            Toast.show("票据上传超时，请稍后重试");
        }
    }
    useEffect(() => {
        getManagerChargeInfo({}).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage)
            } else {
                setChargeInfo(data.result);
                setFileList(data.result.qrcode.map((url: any) => ({url})))
            }
        })
    }, []);
    useEffect(() => {
        setAlipayStatus(chargeInfo?.alipay)
    }, [chargeInfo]);

    const handleAlipayStatusChange = (val: boolean) => {
        setLoading(true);
        postManagerChargeUpdate({ key: "alipay", val }).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                setAlipayStatus(val);
            }
            setLoading(false);
        })
    }
    const handleQRCodeUpload = () => {
        postManagerChargeUpdate({ key: "qrcode", val: fileList.map((file) => file.url) }).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                getManagerChargeInfo({}).then(({data}) => {
                    if(data.status === 'error') {
                        Toast.show(data.errorMessage);
                    } else {
                        setEditVisible(false);
                        setChargeInfo(data.result);
                    }
                })
            }
        })
    }
    return (
        <div>
            <NavBar onBack={() => navigate(-1)}>
                收款设置
            </NavBar>
            <List>
                <List.Item 
                    extra={<Switch checked={alipayStatus} onChange={handleAlipayStatusChange}/>}
                    description='请确保支付宝和实名认证对应，否则金额将无法正确打款到您的支付宝'>
                    支付宝支付
                </List.Item>
            </List>
            <Collapse accordion defaultActiveKey={"1"}>
                <Collapse.Panel key='1' title='二维码收款'>
                    {
                        editVisible ? (
                        <div>
                            <ImageUploader
                                value={fileList}
                                onChange={setFileList}
                                upload={handleFileUpload as any} />
                            <div style={{ marginTop: '10px' }}>
                                <Button block color='primary' onClick={handleQRCodeUpload}>确认修改</Button>
                                <Button block color='warning' onClick={() => setEditVisible(false)}>取消修改</Button>
                            </div>
                        </div>
                        ) : (
                            <div>
                                <Space wrap>
                                    {
                                        chargeInfo.qrcode.map((img) => {
                                            return (
                                                <Image
                                                    src={img}
                                                    width={80}
                                                    height={80}
                                                    fit='cover'
                                                    style={{ borderRadius: 4 }}
                                                />
                                            )
                                        })
                                    }
                                </Space>
                                <div style={{ marginTop: '10px' }}>
                                    <Button block color='primary' onClick={() => setEditVisible(true)}>修改二维码</Button>
                                </div>
                            </div>
                        )
                    }
                    
                    
                </Collapse.Panel>
            </Collapse>
        </div>
    )
}