import React, { useState, useMemo } from "react";
import { Button, Dialog, List, Mask, NavBar, Switch, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { postManagerShopBasicInfo, postManagerShopDelete } from "../../axios";
import { getDay, getOneDayEnd, getOneDayStart, getOneMonthEnd, getOneMonthStart } from "../../utils";
import DatePicker from "../../components/DatePicker.tsx";

export default function AutoDelete() {
    const navigate = useNavigate();
    const shop = useSelector((state) => state.shop?.shop || {});
    const [autoDelete, setAutoDelete] = useState(shop.autoDelete);
    const [loading, setLoading] = useState(false);
    const lastMonth = useMemo(() => getOneMonthStart(Date.now()) - 24 * 60 * 60 * 1000, []);
    const [selfDeleteVisible, setSelfDeleteVisible] = useState(false);
    const [startTime, setStartTime] = useState(getOneMonthStart(lastMonth));
    const [endTime, setEndTime] = useState(getOneMonthEnd(lastMonth));
    const [datePickerStartVisible, setDatePickerStartVisible] = useState(false);
    const [datePickerEndVisible, setDatePickerEndVisible] = useState(false);
    
    const handleAutoDeleteChaneg = (val) => {
        setLoading(true);
        postManagerShopBasicInfo({ key: 'autoDelete', val }).then(({ data }) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                setAutoDelete(val);
            }
            setLoading(false);
        })
    }
    const handleSelfDeleteConfirm = () => {
        setSelfDeleteVisible(false);
        Dialog.show({
            content: `确认删除${getDay(startTime)}到${getDay(endTime)}的数据么，数据删除不可恢复`,
            closeOnAction: true,
            actions: [
                [
                    {
                        key: 'cancel',
                        text: '取消',
                    },
                    {
                        key: 'delete',
                        text: '删除',
                        bold: true,
                        danger: true,
                    },
                ],
            ],
            onAction: (action) => {
                if(action.key === 'delete') {
                    postManagerShopDelete({ startTime, endTime }).then(({data}) => {
                        if(data.status === 'error') {
                            Toast.show(data.errorMessage);
                        } else {
                            Toast.show('删除成功');
                        }
                    })
                }
            }
        })
    }
    return (
        <div>
            <NavBar
                onBack={() => navigate(-1)}>
                数据清理
            </NavBar>
            <List>
                <List.Item 
                    extra={<Switch checked={autoDelete} loading={loading} onChange={handleAutoDeleteChaneg}/>}
                    description='打开此开关后,每月16号凌晨会自动清除上个月店铺内的所有订单、充提、服务费等数据'>
                    自动清除
                </List.Item>
                <List.Item clickable onClick={() => setSelfDeleteVisible(true)}>
                    自选清除
                </List.Item>
            </List>
            <Mask visible={selfDeleteVisible} onMaskClick={() => setSelfDeleteVisible(false)}>
                <div style={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                    <div style={{
                    backgroundColor: "white",
                    margin: "10px",
                    padding: '10px'
                    }}>
                        <div>删除时间段内的店铺内的所有订单、充提、服务费等数据</div>
                        <div className="topBox">
                            <div className="top">
                                <div className="dataBox" onClick={() => setDatePickerStartVisible(true)}>
                                    <div>
                                        <div className="uni-input">{ getDay(startTime) }</div>
                                    </div>
                                </div>
                                <span><span>至</span></span>
                                <div className="dataBox" onClick={() => setDatePickerEndVisible(true)}>
                                    <div><div className="uni-input">{ getDay(endTime) }</div></div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <Button block color="primary" onClick={handleSelfDeleteConfirm}>确认删除</Button>
                            <Button block color="success" onClick={() => setSelfDeleteVisible(false)}>取消</Button>
                        </div>
                    </div>
                </div>
            </Mask>
            <Mask visible={datePickerStartVisible} onMaskClick={() => setDatePickerStartVisible(false)}>
                <div>
                    <DatePicker defaultTime={startTime} onChange={(time, precision) => {
                        setDatePickerStartVisible(false);
                        if(precision === "day") {
                            setStartTime(getOneDayStart(time));
                        }
                    }} />
                </div>
            </Mask>
            <Mask visible={datePickerEndVisible} onMaskClick={() => setDatePickerEndVisible(false)}>
                <div>
                    <DatePicker defaultTime={endTime} onChange={(time, precision) => {
                        setDatePickerEndVisible(false);
                        if(precision === "day") {
                            setEndTime(getOneDayEnd(time));
                        }
                    }} />
                </div>
            </Mask>
        </div>
    )
}