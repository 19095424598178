import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, NavBar, Toast } from "antd-mobile";
import { getManagerStatisticsShopBalance } from "../../axios";
import "./StatisticsBalance.css";
import statistics1 from "../../assets/shop/statistics-1.png";
import statistics2 from "../../assets/shop/statistics-2.png";

export default function StatisticsShopBalance() {
    const navigate = useNavigate();
    const [commission, setCommission] = useState(0);
    const [commissionPay, setCommissionPay] = useState(0);
    const [total, setTotal] = useState(0);
    const [totalAdd, setTotalAdd] = useState(0);
    const [totalSub, setTotalSub] = useState(0);
    useEffect(() => {
        getManagerStatisticsShopBalance({}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setCommission(data.commission.amount);
                setCommissionPay(data.commissionPay.amount);
                setTotal(data.commission.amount - data.commissionPay.amount);
                setTotalAdd(data.commission.amount);
                setTotalSub(data.commissionPay.amount);
            }
        })
    }, [])
    return (
        <div className="statistics-balance">
            <NavBar onBack={() => navigate(-1)}>
                今日店铺余额变动
            </NavBar>
            <div>
                <div className="header">
                    <div style={{ fontSize: "24px" }}>店铺余额变动</div>
                    <div style={{ fontSize: "24px" }}>{total}</div>
                </div>
                <div style={{ padding: "20px 25px" }}>
                    <div style={{
                        fontSize: "24px",
                        backgroundImage: `url(${statistics1})`,
                        backgroundSize: "cover",
                        height: "70px",
                        padding: "15px 25px"
                    }}>店铺收入+{totalAdd.toFixed(2)}</div>
                    <div style={{ textAlign: "center" }}>
                        <Grid columns={4} gap={8}>
                            <Grid.Item>
                                <div>0</div>
                                <div>发单佣金</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>{ commission }</div>
                                <div>跟单佣金</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>0</div>
                                <div>异常加款</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>0</div>
                                <div>店主充值</div>
                            </Grid.Item>
                        </Grid>
                    </div>
                </div>
                <div style={{ padding: "20px 25px" }}>
                    <div style={{
                        fontSize: "24px",
                        backgroundImage: `url(${statistics2})`,
                        backgroundSize: "cover",
                        height: "70px",
                        padding: "15px 25px"
                    }}>店铺支出-{totalSub.toFixed(2)}</div>
                    <div style={{ textAlign: "center" }}>
                        <Grid columns={4} gap={8}>
                            <Grid.Item>
                                <div>0</div>
                                <div>服务费支出</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>{commissionPay}</div>
                                <div>跟单佣金</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>0</div>
                                <div>异常扣款</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>0</div>
                                <div>提现</div>
                            </Grid.Item>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}
