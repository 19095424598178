import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import { Avatar, Divider, Grid, Mask, NavBar, Switch, Toast } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import DatePicker from "../../components/DatePicker.tsx";

import "./AgentManagement.css";
import { changeUserAgentStatus, getManagerAgentList, getManagerAgentSum } from "../../axios";
import { getDay, getOneDayEnd, getOneDayStart, getOneMonthEnd, getOneMonthStart } from "../../utils";
import defaultUser from "../../assets/images/user-2.jpg";

export default function AgentManagement() {
    const navigate = useNavigate();
    const now = useMemo(() => ( Date.now() ), []);
    const [startTime, setStartTime] = useState(getOneMonthStart(now));
    const [endTime, setEndTime] = useState(getOneMonthEnd(now));
    const [datePickerStartVisible, setDatePickerStartVisible] = useState(false);
    const [datePickerEndVisible, setDatePickerEndVisible] = useState(false);
    const [amount, setAmount] = useState(0);
    const [agentList, setAgentList] = useState<any[]>([]);
    const agentNum = useMemo(() => {
        return agentList.length;
    }, [agentList])
    useEffect(() => {
        getManagerAgentList({ startTime, endTime, status: true }).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                const { result } = data;
                setAgentList(result);
                let _amount = 0;
                for(let agent of result) {
                    _amount += agent.totalSpend;
                }
                setAmount(_amount);
            }
        });
    }, [endTime, startTime]);

    const handleChangeAgentStatus = (userId: string, isAgent: boolean) => {
        changeUserAgentStatus({userId, isAgent}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
                if(data.errorCode == 20002) {
                    navigate(`/manager/agent/userlist/${userId}`)
                }
            } else {
                getManagerAgentList({ startTime, endTime, status: true }).then(({data}) => {
                    setAgentList(data.result);
                });
            }
        })
    };
    return (
        <div className="agent-management">
            <NavBar onBack={() => navigate(-1)}>
                代理统计
            </NavBar>
            <div className="agent-management-box">
                <div className="topBox">
                    <div className="top">
                        <div className="dataBox" onClick={() => setDatePickerStartVisible(true)}>
                            <div>
                                <div className="uni-input">{ getDay(startTime) }</div>
                            </div>
                        </div>
                        <span><span>至</span></span>
                        <div className="dataBox" onClick={() => setDatePickerEndVisible(true)}>
                            <div><div className="uni-input">{ getDay(endTime) }</div></div>
                        </div>
                    </div>
                </div>
                <Mask visible={datePickerStartVisible} onMaskClick={() => setDatePickerStartVisible(false)}>
                    <div>
                        <DatePicker defaultTime={startTime} onChange={(time, precision) => {
                            setDatePickerStartVisible(false);
                            if(precision === "day") {
                                setStartTime(getOneDayStart(time));
                            }
                        }} />
                    </div>
                </Mask>
                <Mask visible={datePickerEndVisible} onMaskClick={() => setDatePickerEndVisible(false)}>
                    <div>
                        <DatePicker defaultTime={endTime} onChange={(time, precision) => {
                            setDatePickerEndVisible(false);
                            if(precision === "day") {
                                setEndTime(getOneDayEnd(time));
                            }
                        }} />
                    </div>
                </Mask>
                <Grid columns={3} gap={12} style={{marginTop: "12px"}}>
                    <Grid.Item>
                        <div className="column agent-detail-box">
                            <div className="personal-tips">代理注册</div>
                            <div className="personal-label">0 人</div>
                        </div>
                    </Grid.Item>
                    <Grid.Item>
                        <div className="column agent-detail-box">
                            <div className="personal-tips">代理销售</div>
                            <div className="personal-label">{amount.toFixed(0)} 元</div>
                        </div>
                    </Grid.Item>
                    <Grid.Item>
                        <div className="column agent-detail-box">
                            <div className="personal-tips">应付佣金</div>
                            <div className="personal-label">0 元</div>
                        </div>
                    </Grid.Item>
                </Grid>
            </div>
            <div style={{ marginTop: "12px" }}>代理列表（{agentNum}个）</div>
            <div className="agent-list">
                {
                    agentList.map((agent) => {
                        const { agent: user, totalSpend, totalBonus } = agent; 
                        return (
                            <div className="agent-management-box" style={{ marginTop: "12px" }} onClick={() => navigate(`/manager/agent/statistics/${user._id}`)}>
                                <div className="agent-list-item-top row space-between align-items-center">
                                    <div className="agent-list-item-top-left row  align-items-center">
                                        {/* <TeamFill fontSize={24}/> */}
                                        <Avatar src={user.avatar || defaultUser}/>
                                        <div className="agent-list-item-top-left-text">{ user.remark || user.username }</div>
                                    </div>
                                    <RightOutline />
                                </div>
                                <Divider />
                                <Grid columns={4} gap={12}>
                                    {/* <Grid.Item>
                                        <div className="column align-items-center">
                                            <div className="personal-tips">
                                                代理时长
                                            </div>
                                            <div className="personal-tips-value">
                                                {getDayFromCreateDate(createTime)} 天
                                            </div>
                                        </div>
                                    </Grid.Item> */}
                                    <Grid.Item>
                                        <div className="column align-items-center">
                                            <div className="personal-tips">
                                                销售额
                                            </div>
                                            <div className="personal-tips-value">
                                                ¥ {totalSpend.toFixed(0)}
                                            </div>
                                        </div>
                                    </Grid.Item>
                                    <Grid.Item>
                                        <div className="column align-items-center">
                                            <div className="personal-tips">
                                                派奖金额
                                            </div>
                                            <div className="personal-tips-value">
                                                ¥ {totalBonus.toFixed(0)}
                                            </div>
                                        </div>
                                    </Grid.Item>
                                    <Grid.Item>
                                        <div className="column align-items-center">
                                            <div className="personal-tips">
                                                派奖率
                                            </div>
                                            <div className="personal-tips-value">
                                                {( totalSpend > 0 ? totalBonus / totalSpend * 100 : 0 ).toFixed(0)}%
                                            </div>
                                        </div>
                                    </Grid.Item>
                                    <Grid.Item>
                                        <div className="column align-items-center">
                                            <div className="personal-tips">
                                                状态
                                            </div>
                                            <div className="personal-tips-value" onClick={(e) => {
                                                e.stopPropagation();
                                                e.nativeEvent.stopImmediatePropagation();
                                            }}>
                                                <Switch 
                                                    style={{ '--height': "18px", '--width': "18px", marginTop: "-6px"}} 
                                                    defaultChecked={true}
                                                    onChange={(val) => {
                                                        handleChangeAgentStatus(user._id, val)
                                                    }}/>
                                            </div>
                                        </div>
                                    </Grid.Item>
                                </Grid>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}