import { Avatar, Button, Dialog, Input, List, Mask, NavBar, SpinLoading, Switch, Tag, Toast, Space } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    getManagerUserInfo,
    changeUserBalance,
    changeUserAgentStatus,
    postEditUserRemark,
    changeUserStar,
    changeUserStatus,
    changeUserTransfer,
    getShopPayPasswordExist,
    changeUserTag
} from "../../axios/index.js";
import { getTime } from "../../utils/index.ts";
import defaultUser from "../../assets/images/user-2.jpg";
import { EditSOutline, StarFill, StarOutline } from "antd-mobile-icons";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
export default function UserInfo() {
    const params = useParams();
    const navigate = useNavigate();
    const shop = useSelector((state) => state.shop.shop);
    const isMasterShop = useMemo(() => shop._id === "6528737d3f59abb12181c477", [shop]);
    const [user, setUser] = useState<any>({});
    const [psw, setPsw] = useState("");
    const [isAgent, setIsAgent] = useState(false);
    const [isStarUser, setIsStarUser] = useState(false);
    const [userStatus, setUserStatus] = useState(true);
    const [userTag, setUserTag] = useState('');
    const [transferStatus, setTransferStatus] = useState(false);
    const [agentSwitchLoading, setAgentSwitchLoading] = useState(true);
    const [starUserSwitchLoading, setStarUserSwitchLoading] = useState(true);
    const [statusSwitchLoading, setStatusSwitchLoading] = useState(true);
    const [transferSwitchLoading, setTransferSwitchLoading] = useState(true);
    const [remarkEditModalVisible, setRemarkEditModalVisible] = useState(false);
    const [remark, setRemark] = useState("");
    const [payPasswordExist, setPayPasswordExist] = useState(true);
    const [changeBalance, setChangeBalance] = useState("");
    const [addBalanceModalVisible, setAddBalanceModalVisible] = useState(false);
    const [subBalanceModalVisible, setSubBalanceModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getManagerUserInfo({id: params.id}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setUser(data.user);
                setRemark(data.user.remark);
                if(data.user.role === "agent") {
                    setIsAgent(true);
                }
                if(data.user.starUser) {
                    setIsStarUser(true);
                }
                setUserStatus(data.user.status);
                setTransferStatus(data.user.transferStatus);
                setUserTag(data.user.tag);
            }
            setAgentSwitchLoading(false);
            setStarUserSwitchLoading(false);
            setStatusSwitchLoading(false);
            setTransferSwitchLoading(false);
        });
        getShopPayPasswordExist({}).then(({data}) => {
            setPayPasswordExist(data.existPayPassword);
        });
    }, [params.id])

    const handleChangeBalance = async(type: string) => {
        setLoading(true);
        setAddBalanceModalVisible(false);
        setSubBalanceModalVisible(false);
        setChangeBalance("");
        setPsw("");
        if(!payPasswordExist) {
            Toast.show("未设置支付密码");
            Dialog.show({
                content: '是否前往设置支付密码',
                closeOnAction: true,
                actions: [
                    {
                        key: "confirm",
                        text: "确定"
                    },
                    {
                        key: 'cancel',
                        text: '取消',
                    },
                ],
                onAction: (action) => {
                    if (action.key === "confirm") {
                        navigate("/manager/paypassword");
                    }
                }
            });
            return;
        }
        changeUserBalance({
            type: type,
            value: changeBalance,
            payPassword: psw,
            userId: user._id
        }).then(({data}) => {
            setLoading(false);
            if(data.status === "error") {
                Toast.show(data.errorMessage);
                if(data.errorCode === 10001) {
                    Dialog.show({
                        content: '是否前往设置支付密码',
                        closeOnAction: true,
                        actions: [
                            {
                                key: "confirm",
                                text: "确定"
                            },
                            {
                                key: 'cancel',
                                text: '取消',
                            },
                        ],
                        onAction: (action) => {
                            if (action.key === "confirm") {
                                navigate("/manager/paypassword");
                            }
                        }
                    })
                }
            } else {
                setUser({
                    ...user,
                    balance: data.balance
                });
            }
        })
    };

    const handleSwitchAgentStatus = async(val: boolean) => {
        if(user.role === "manager") {
            Toast.show({ content: '无法更改管理员' })
        } else {
            setAgentSwitchLoading(true);
            try {
                const {data} = await changeUserAgentStatus({ userId: user._id, isAgent: val });
                if(data.status === "error") {
                    if(data.errorCode === 20002) {
                        Dialog.confirm({
                            content: '是否转移该代理下的用户',
                            onConfirm: async () => {
                                navigate(`/manager/agent/userlist/${user._id}`);
                            },
                            onCancel: async() => {
                                const { data } = await changeUserAgentStatus({ userId: user._id, isAgent: val, noTransfer: true });
                                if(data.status === "error") {
                                    Toast.show(data.errorMessage);
                                } else {
                                    setIsAgent(val);
                                }
                            }
                          })
                    } else {
                        Toast.show(data.errorMessage);
                    }
                } else {
                    setIsAgent(val);
                }
            } catch(e) {
                Toast.show("请求错误");
            }
            setAgentSwitchLoading(false);
        }
    };

    const handleSwitchStarStatus = async(val: boolean) => {
        setStarUserSwitchLoading(true);
        const { data } = await changeUserStar({ userId: user._id, starUser: val })
        if(data.status === "error") {
            Toast.show(data.errorMessage);
        } else {
            setIsStarUser(val);
        }
        setStarUserSwitchLoading(false);
    }

    const handleSwitchTag = async(val: string) => {
        if(userTag === val) return;
        const { data } = await changeUserTag({ userId: user._id, tag: val })
        if(data.status === "error") {
            Toast.show(data.errorMessage);
        } else {
            setUserTag(val);
        }
        setStarUserSwitchLoading(false);
    }

    const handleSwitchUserStatus = async(val: boolean) => {
        setStatusSwitchLoading(true);
        const { data } = await changeUserStatus({ userId: user._id, status: !val })
        if(data.status === "error") {
            Toast.show(data.errorMessage);
        } else {
            setUserStatus(!val);
        }
        setStatusSwitchLoading(false);
    }
    const handleSwitchTransferStatus = async(val: boolean) => {
        console.log(val);
        setTransferSwitchLoading(true);
        const { data } = await changeUserTransfer({ userId: user._id, status: val });
        if(data.status === "error") {
            Toast.show(data.errorMessage);
        } else {
            setTransferStatus(val);
        }
        setTransferSwitchLoading(false);
    }

    const handleRemarkEdit = async() => {
        setRemarkEditModalVisible(false);
        try {
            const { data } = await postEditUserRemark({ userId: user._id, remark });
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setUser({...user, remark})
            }
        } catch(e) {
            Toast.show("请求错误");
        }
    }
    return (
        <div>
            <NavBar onBack={() => navigate(-1)}>
                用户详情
            </NavBar>
            <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px", padding: "15px 15px" }}>
                <div>
                    <Avatar src={user.avatar || defaultUser} style={{ '--size': '64px' }}/>
                </div>
                <div style={{ flex: "1 1" }}>
                    <div>
                        <div onClick={() => setRemarkEditModalVisible(true)}>备注：{user.remark || user.username} <EditSOutline /></div>
                        <div>昵称：{user.username}</div>
                        <div>
                            电话：{user.phone}
                            <Button color='primary' fill='none' size="small" onClick={(e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                copy(user.phone)
                            }}>
                                复制
                            </Button>
                        </div>
                        <div>注册: {getTime(user.createTime)}</div>
                    </div>
                </div>
                <div>
                    {
                        isStarUser ?  <StarFill fontSize={"24px"} color='var(--adm-color-danger)'/> : <StarOutline fontSize={"24px"}/>
                    }
                </div>
            </div>
            <div style={{padding: "12px"}}>
                账户余额（元）
                <div style={{ display: "flex" }}>
                    <div style={{ flexGrow: 1, fontSize: "36px" }}>{user.balance?.toFixed(2)}</div>
                    <div>
                        <Button style={{marginRight: "10px"}} onClick={() => setAddBalanceModalVisible(true)}>加款</Button>
                        <Button color='success' onClick={() => setSubBalanceModalVisible(true)}>扣款</Button>
                    </div>
                </div>
            </div>
            <List header=''>
                <List.Item onClick={() => {navigate("/manager/user/actionlist", { state: { id: params.id } })}}>账户明细</List.Item>
                <List.Item onClick={() => {navigate("/manager/user/betlist", { state: { id: params.id } })}}>投注记录</List.Item>
                <List.Item extra={
                    <Switch loading={agentSwitchLoading} checked={isAgent}
                        onChange={handleSwitchAgentStatus}
                    />
                }>设为用户代理</List.Item>
                <List.Item extra={
                    <Switch loading={starUserSwitchLoading} checked={isStarUser}
                        onChange={handleSwitchStarStatus}
                    />
                }>星标用户</List.Item>
                {
                    isMasterShop && (
                        <List.Item extra={
                            <Space>
                                <Tag
                                    color={ userTag === 'offline' ? "success" : 'default'}
                                    onClick={() => handleSwitchTag('offline')}>线下</Tag>
                                <Tag
                                    color={ userTag === 'online' ? "success" : 'default'}
                                    onClick={() => handleSwitchTag('online')}>线上</Tag>
                            </Space>
                        }>用户标签</List.Item>
                    )
                }
                <List.Item extra={
                    <Switch loading={transferSwitchLoading} checked={transferStatus}
                        onChange={handleSwitchTransferStatus}
                    />
                }>转账权限</List.Item>
                <List.Item extra={
                    <Switch loading={statusSwitchLoading} checked={!userStatus}
                        onChange={handleSwitchUserStatus}
                    />
                }>拉黑用户{userStatus}</List.Item>
                <List.Item extra={user.agentId?.username} clickable onClick={() => navigate(`/manager/user/agent/${user._id}`, { state: { agent: user.agentId, user } })}>他的代理</List.Item>
            </List>
            <Mask visible={remarkEditModalVisible}>
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        width: "60vh",
                        height: "auto",
                        backgroundColor: "white",
                        padding: "10px"
                    }}>
                        <div style={{ textAlign: "center" }}>设置备注</div>
                        <div style={{
                            margin: "10px 0"
                        }}><Input placeholder="请输入备注" value={remark} onChange={setRemark}/></div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around"
                        }}>
                            <div
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={() => { setRemark(user.remark); setRemarkEditModalVisible(false) }}>取消</div>
                            <div 
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={handleRemarkEdit}>确认</div>
                        </div>
                    </div>
                </div>
            </Mask>
            <Mask visible={addBalanceModalVisible}>
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        width: "60vh",
                        height: "auto",
                        backgroundColor: "white",
                        padding: "30px",
                        borderRadius: "10px",
                    }}>
                        <div style={{ textAlign: "center", fontSize: "18px" }}>加款</div>
                        <div style={{
                            margin: "10px 0"
                        }}>
                            <div style={{ display: "flex", margin: "10px" }}>
                                <div style={{ width: "100px" }}>加款金额</div>
                                <Input placeholder='请输入金额' clearable value={changeBalance} type="number" onChange={(data) => setChangeBalance(data)}/>
                            </div>
                            <div style={{ display: "flex", margin: "10px" }}>
                                <div style={{ width: "100px" }}>支付密码</div>
                                <Input placeholder='请输入密码' clearable value={psw} type='password' onChange={setPsw} />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around"
                        }}>
                            <div
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={() => {
                                    setAddBalanceModalVisible(false);
                                    setChangeBalance("");
                                    setPsw("");
                                }}>取消</div>
                            <div 
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={() => handleChangeBalance('add')}>确认</div>
                        </div>
                    </div>
                </div>
            </Mask>
            <Mask visible={subBalanceModalVisible}>
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        width: "60vh",
                        height: "auto",
                        backgroundColor: "white",
                        padding: "30px",
                        borderRadius: "10px",
                    }}>
                        <div style={{ textAlign: "center", fontSize: "18px" }}>扣款</div>
                        <div style={{
                            margin: "10px 0"
                        }}>
                            <div style={{ display: "flex", margin: "10px" }}>
                                <div style={{ width: "100px" }}>扣款金额</div>
                                <Input placeholder='请输入金额' clearable value={changeBalance} type="number" onChange={(data) => setChangeBalance(data)}/>
                            </div>
                            <div style={{ display: "flex", margin: "10px" }}>
                                <div style={{ width: "100px" }}>支付密码</div>
                                <Input placeholder='请输入密码' clearable value={psw} type='password' onChange={setPsw} />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around"
                        }}>
                            <div
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={() => {
                                    setSubBalanceModalVisible(false);
                                    setChangeBalance("");
                                    setPsw("");
                                }}>取消</div>
                            <div 
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={() => handleChangeBalance('sub')}>确认</div>
                        </div>
                    </div>
                </div>
            </Mask>
            <Mask visible={loading}>
                <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <SpinLoading style={{ '--size': '24px' }} />
                </div>
            </Mask>
        </div>
    )
}