import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./PersonalCenter.css"
import { useDispatch, useSelector } from "react-redux";
import { clear, set } from "../../store/shopSlice.ts";

import { AutoCenter, Badge, Button, Grid, Image, List, Space, Switch, Toast } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import DefaultUser from "../../assets/shop/user-avatar.png";
import Icon1PNG from "../../assets/shop/person-icon-1.png"
import Icon2PNG from "../../assets/shop/person-icon-2.png"
import Icon3PNG from "../../assets/shop/person-icon-3.png"
import Icon4PNG from "../../assets/shop/person-icon-4.png"
import Icon5PNG from "../../assets/shop/person-icon-5.png"
import Icon6PNG from "../../assets/shop/person-icon-6.png"
import Icon7PNG from "../../assets/shop/person-icon-7.png"
import Icon8PNG from "../../assets/shop/person-icon-8.png"

import Footer from "../../components/ManagerFooter";
import { getManagerShopBasicInfo, getManagerShopUndo, logout, postManagerShopBasicInfo } from "../../axios";
import { getOneMonthEnd, getOneMonthStart } from "../../utils/index.ts";

const adminAvatar = "https://tc-lottery.oss-cn-guangzhou.aliyuncs.com/chat-avatar/Avatar-4.png";

export default function PersonalCenter() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [shopInfo, setShopInfo] = useState<any>({});
    const [undoBet, setUndoBet] = useState(0);
    const [undoDraw, setUndoDraw] = useState(0);
    const [undoPay, setUndoPay] = useState(0);
    const [loading, setLoading] = useState(true);
    const [shopStatus, setShopStatus] = useState(false);
    const version = useMemo(() => localStorage.getItem('_v'), []);
    useEffect(() => {
        getManagerShopBasicInfo({}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                const { shop } = data;
                dispatch(set(shop));
                setShopInfo(shop);
                setShopStatus(shop.status);
                setLoading(false);
            }
        });
        getManagerShopUndo({}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setUndoBet(data.undoBetNum);
                setUndoDraw(data.undoDrawNum);
                setUndoPay(data.undoPayNum);
            }
        })
        const timmer = setInterval(() => {
            getManagerShopUndo({}).then(({data}) => {
                if(data.status === "error") {
                    Toast.show(data.errorMessage);
                } else {
                    setUndoBet(data.undoBetNum);
                    setUndoDraw(data.undoDrawNum);
                    setUndoPay(data.undoPayNum);
                }
            })
        }, 5 * 1000)
        return () => clearInterval(timmer);
    }, [dispatch]);
    const handleLogout = async() => {
        logout({}).then(() =>{
            dispatch(clear());
            localStorage.setItem('token', '');
            navigate("/login");
        })
    }

    const handleToggleStatus = async(val: boolean) => {
        postManagerShopBasicInfo({ key: "status", val }).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setShopStatus(val);
                setShopInfo({
                    ...shopInfo,
                    status: val
                });
            }
        })
    }
    return (
        <div className="shop-personal-center" style={{display: "flex", height: "100%", flexDirection: "column", backgroundColor: "var(--whole-page-background)"}}>
            <div style={{ flex: "1 1", overflowY: "scroll" }}>
                <div className="personalInfoContainer">
                    
                    <div style={{ display: "flex", marginTop: "15px", zIndex: 1 }}>
                        <Space direction="horizontal" align="start">
                            <div className="avatar">
                                <Image src={DefaultUser} width={50} height={50} fit='fill' style={{ borderRadius: "50%" }}/>
                            </div>
                        </Space>
                        <div className="row space-between flex-1">
                            <div className="name column space-between flex-1">
                                <div>{shopInfo.name}(编号{shopInfo.shopId})</div>
                                <div style={{
                                    marginTop: "10px"
                                }}>
                                    {
                                        shopStatus ? <>
                                                <span style={{
                                                    backgroundColor: "green",
                                                    padding: "3px 12px"
                                                }}>营业中</span>
                                                <span style={{
                                                    backgroundColor: "#e44c49",
                                                    padding: "3px 12px"
                                                }} onClick={() => handleToggleStatus(false)}>下线</span>
                                            </> : <>
                                            <span style={{
                                                    backgroundColor: "gray",
                                                    padding: "3px 12px"
                                                }}>休息中</span>
                                                <span style={{
                                                    backgroundColor: "#e44c49",
                                                    padding: "3px 12px"
                                                }} onClick={() => handleToggleStatus(true)}>上线</span>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="row center personal-publisher"
                                onClick={() => navigate("/share/shop", { state: { from: 3, agentKey: shopInfo.shopAgentKey } })}>
                                推广店铺
                            </div>
                        </div>
                    </div>
                    <div className="slogan">
                        <div className="bg-1" onClick={() => navigate("/manager/chat/private", { state: { id: "651ad6d363a8753af3893a10", name: "红单客服", avatar: adminAvatar } })}></div>
                    </div>
                    <div className="shop-balance">
                        <div className="column bg-2 balance-block" onClick={() => { navigate("/manager/shop/action") }}>
                            <div className="personal-highlight">{shopInfo.shopBalance?.toFixed(2)}</div>
                            <div className="person-ps">店铺余额<RightOutline /></div>
                        </div>
                        <div className="column bg-3 balance-block" onClick={() => { navigate("/manager/user") }}>
                            <div className="personal-highlight">{shopInfo.userBalance?.toFixed(2)}</div>
                            <div className="person-ps">托管余额<RightOutline /></div>
                        </div>
                    </div>
                    <div className="manage-tools column space-around" style={{height: "180px"}}>
                        <Grid columns={4} gap={14}>
                            <Grid.Item>
                                <AutoCenter>
                                    <Badge content={undoBet ? undoBet : null}>
                                        <Space direction="vertical" align="center" onClick={() => {
                                            const now = Date.now();
                                            navigate("/manager/statistics/bet", { state: { status: "0,1", startTime: getOneMonthStart(now), endTime: getOneMonthEnd(now) } })
                                        } }>
                                            <img className="personal-tools-icon" src={Icon1PNG} alt="AllInHistory" />
                                            <span className="personalInfoToolItem">等待出票</span>
                                        </Space>
                                    </Badge>
                                </AutoCenter>
                            </Grid.Item>
                            <Grid.Item>
                                <AutoCenter>
                                    <Badge content={undoDraw ? undoDraw : null}>
                                        <Space direction="vertical" align="center" onClick={() => navigate("/manager/draw/list")}>
                                            <img className="personal-tools-icon" src={Icon2PNG} alt="TrackHistory" />
                                            <span className="personalInfoToolItem">用户提现</span>
                                        </Space>
                                    </Badge>
                                </AutoCenter>
                            </Grid.Item>
                            <Grid.Item>
                                <div onClick={() => navigate("/manager/bet/list")}>
                                    <AutoCenter>
                                        <Space direction="vertical" align="center">
                                            <img className="personal-tools-icon" src={Icon3PNG} alt="SaveHistory" />
                                            <span className="personalInfoToolItem">订单查询</span>
                                        </Space>
                                    </AutoCenter>
                                </div>
                            </Grid.Item>
                            <Grid.Item>
                                <Badge content={undoPay ? undoPay : null}>
                                    <div onClick={() => navigate("/manager/payorders")}>
                                        <AutoCenter>
                                            <Space direction="vertical" align="center">
                                                <img className="personal-tools-icon" src={Icon4PNG} alt="StoreInfo" />
                                                <span className="personalInfoToolItem">派奖管理</span>
                                            </Space>
                                        </AutoCenter>
                                    </div>
                                </Badge>
                            </Grid.Item>
                        </Grid>
                        <Grid columns={4} gap={14}>
                            <Grid.Item>
                                <AutoCenter>
                                    <Space direction="vertical" align="center" onClick={() => { navigate("/manager/shop/charge") } }>
                                        <img className="personal-tools-icon" src={Icon5PNG} alt="AllInHistory" />
                                        <span className="personalInfoToolItem">店铺充值</span>
                                    </Space>
                                </AutoCenter>
                            </Grid.Item>
                            <Grid.Item>
                                <AutoCenter>
                                    <Space direction="vertical" align="center" onClick={() => { navigate("/manager/chat/group") }}>
                                        <img className="personal-tools-icon" src={Icon6PNG} alt="TrackHistory" />
                                        <span className="personalInfoToolItem">店铺群聊</span>
                                    </Space>
                                </AutoCenter>
                            </Grid.Item>
                            <Grid.Item>
                                <div onClick={() => navigate("/share/shop/register")}>
                                    <AutoCenter>
                                        <Space direction="vertical" align="center">
                                            <img className="personal-tools-icon" src={Icon7PNG} alt="SaveHistory" />
                                            <span className="personalInfoToolItem">邀请店主</span>
                                        </Space>
                                    </AutoCenter>
                                </div>
                            </Grid.Item>
                            <Grid.Item>
                                <Badge>
                                    <div onClick={() => navigate("/manager/agent/management")}>
                                        <AutoCenter>
                                            <Space direction="vertical" align="center">
                                                <img className="personal-tools-icon" src={Icon8PNG} alt="StoreInfo" />
                                                <span className="personalInfoToolItem">代理统计</span>
                                            </Space>
                                        </AutoCenter>
                                    </div>
                                </Badge>
                            </Grid.Item>
                        </Grid>
                    </div>
                    <div className="person-container-bg"></div>
                </div>
                <div style={{ borderRadius: '25px', overflow: "hidden" }}>
                    <List className="manage-tools-list" style={{padding: "0px"}}>
                        <List.Item prefix={<div className="prefix-1"></div>} onClick={() => { navigate("/manager/chat/converstions") }}>
                            用户消息
                        </List.Item>
                        <List.Item prefix={<div className="prefix-2"></div>} onClick={() => { navigate("/manager/shop/action") }}>
                            账户明细
                        </List.Item>
                        <List.Item prefix={<div className="prefix-3"></div>} onClick={() => { navigate("/manager/charge/setting") }}>
                            收款管理
                        </List.Item>
                        <List.Item prefix={<div className="prefix-4"></div>} onClick={() => {navigate("/manager/bet/management")}}>
                            彩种管理
                        </List.Item>
                        {/* <List.Item prefix={<div className="prefix-5"></div>} onClick={() => {}}>
                            推送设置
                        </List.Item> */}
                        <List.Item prefix={<div className="prefix-6"></div>} onClick={() => {navigate("/manager/employee/management")}}>
                            员工管理
                        </List.Item>
                        {/* <List.Item prefix={<div className="prefix-7"></div>} onClick={() => {}}>
                            合作店铺
                        </List.Item> */}
                        <List.Item prefix={<div className="prefix-11"></div>} onClick={() => { navigate("/manager/shop/setting") }}>
                            设置中心
                        </List.Item>
                    </List>
                    <div style={{ height: "50px", textAlign: "center", lineHeight: "45px" }} onClick={() => handleLogout()}>
                        登出账户
                    </div>
                    <div style={{ textAlign: "center", marginBottom: '10px' }}>
                        V{version}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}