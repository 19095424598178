import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, NavBar, Toast } from "antd-mobile";
import "./StatisticsBalance.css";
import { getManagerStatisticsUserBalance } from "../../axios";
import statistics1 from "../../assets/shop/statistics-1.png";
import statistics2 from "../../assets/shop/statistics-2.png";

export default function StatisticsShopBalance() {
    const navigate = useNavigate();
    const [bonus, setBonus] = useState({});
    const [add, setAdd] = useState({});
    const [order, setOrder] = useState({});
    const [sub, setSub] = useState({});
    const [total, setTotal] = useState(0);
    const [totalAdd, setTotalAdd] = useState(0);
    const [totalSub, setTotalSub] = useState(0);
    useEffect(() => {
        getManagerStatisticsUserBalance({}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setBonus(data.bonus);
                setAdd(data.add);
                setOrder(data.order);
                setSub(data.sub);
                setTotal(data.bonus.amount + data.add.amount - data.order.amount - data.sub.amount);
                setTotalAdd(data.bonus.amount + data.add.amount);
                setTotalSub(data.order.amount + data.sub.amount);
            }
        })
    }, [])
    return (
        <div className="statistics-balance">
            <NavBar onBack={() => navigate(-1)}>
                今日用户余额变动
            </NavBar>
            <div>
                <div className="header">
                    <div style={{ fontSize: "24px" }}>用户余额变动</div>
                    <div style={{ fontSize: "24px" }}>{total.toFixed(2)}</div>
                </div>
                <div style={{ padding: "20px 25px" }}>
                    <div style={{
                        fontSize: "24px",
                        backgroundImage: `url(${statistics1})`,
                        backgroundSize: "cover",
                        height: "70px",
                        padding: "15px 25px"
                    }}>用户收入+{totalAdd.toFixed(2)}</div>
                    <div style={{ textAlign: "center" }}>
                        <Grid columns={4} gap={8}>
                            <Grid.Item>
                                <div>0</div>
                                <div>用户充值</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>{add.amount?.toFixed(2)}</div>
                                <div>店内加款</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>{bonus.amount?.toFixed(2)}</div>
                                <div>派奖收入</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>0</div>
                                <div>其他收入</div>
                            </Grid.Item>
                        </Grid>
                    </div>
                </div>
                <div style={{ padding: "20px 25px" }}>
                    <div style={{
                        fontSize: "24px",
                        backgroundImage: `url(${statistics2})`,
                        backgroundSize: "cover",
                        height: "70px",
                        padding: "15px 25px"
                    }}>用户支出-{totalSub.toFixed(2)}</div>
                    <div style={{ textAlign: "center" }}>
                        <Grid columns={4} gap={8}>
                            <Grid.Item>
                                <div>0</div>
                                <div>用户提现</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>{sub.amount?.toFixed(2)}</div>
                                <div>店内扣款</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>{order.amount?.toFixed(2)}</div>
                                <div>用户下单</div>
                            </Grid.Item>
                            <Grid.Item>
                                <div>0</div>
                                <div>其他支出</div>
                            </Grid.Item>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}