import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Dialog, NavBar, Space, Toast } from "antd-mobile";
import { getManagerBetList, postPayBonus } from "../../axios/index.js";
import { PlayType } from "../../constant/betPlayType.ts";
import { cnNameMap } from "../../constant/cnNameMap.ts";
import { getTime } from "../../utils/index.ts";
import FootballPNG from "../../assets/407.png";
import BasketballPNG from "../../assets/406.png";
import BeijingPNG from "../../assets/405.png";
import PickThreePNG from "../../assets/202.png";
import ChampionPNG from "../../assets/champion.png";

export default function PayOrders() {
    const navigate = useNavigate();
    const [betList, setBetList] = useState<any[]>([]);
    const [payBetList, setPayBetList] = useState<string[]>([]);
    useEffect(() => {
        getManagerBetList({ status: "4", pageNum: 0, pageSize: 20, showPrivacy: false }).then(({ data }) => {
            setBetList(data.betList || []);
        })
    }, []);
    const handlePayTicketsClick = async () => {
        if (payBetList.length === 0) {
            Toast.show("请选择订单");
            return;
        }
        await postPayBonus({
            betList: payBetList
        }).then(({ data }) => {
            if (data.status === "error") {
                if (data.errorCode === 10001) {
                    Dialog.show({
                        content: '店铺余额不足以支付服务费无法派奖，是否充值',
                        closeOnAction: true,
                        onAction: (action) => {
                            if(action.key === 'charge') {
                                navigate('manager/shop/charge');
                            }
                        },
                        actions: [
                            {
                                key: 'cancel',
                                text: '取消',
                            },
                            {
                                key: 'charge',
                                text: '充值',
                            }
                        ]
                    })
                } else {
                    Toast.show(data.errorMessage);
                }
            } else {
                Toast.show("派奖成功");
                getManagerBetList({ status: "4", pageNum: 0, pageSize: 20 }).then(({ data }) => {
                    if (data.status === "error") {
                        Toast.show(data.errorMessage);
                    } else {
                        setBetList(data?.betList || []);
                    }
                })
            }
        })
    };
    const handleSelectAll = () => {
        const _betIdAll = betList.map((bet) => bet._id);
        setPayBetList(_betIdAll);
    }

    const back = async () => {
        navigate(-1);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <NavBar onBack={back} right={<div onClick={() => navigate("/manager/payorders/history")}>派奖历史</div>}>
                派奖管理
            </NavBar>
            <div style={{ flex: "1 1 auto", overflowY: "auto", paddingLeft: "20px" }}>
                <Space direction='vertical' block>
                    <Checkbox.Group value={payBetList} onChange={(list) => setPayBetList(list)}>
                        {
                            betList.map((bet) => {
                                const username = bet.userId.remark || bet.userId.username;
                                const { userBuyTime, lastCloseTime } = bet;
                                const isCrossDay = new Date(userBuyTime).getDay() !== new Date(lastCloseTime).getDay();
                                return (
                                    <Checkbox key={bet._id} value={bet._id}>
                                        <div style={{ display: "flex", padding: "10px 0", fontSize: "13px", backgroundColor: "white", borderRadius: "3px" }}>
                                            <div style={{ marginRight: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {
                                                    (bet.type === "football") && (<img src={FootballPNG} alt="" style={{ width: "40px" }} />)
                                                }
                                                {
                                                    (bet.type === "beijing") && (<img src={BeijingPNG} alt="" style={{ width: "40px" }} />)
                                                }
                                                {
                                                    (bet.type === "basketball") && (<img src={BasketballPNG} alt="" style={{ width: "40px" }} />)
                                                }
                                                {
                                                    (bet.type === "pickThree") && (<img src={PickThreePNG} alt="" style={{ width: "40px" }} />)
                                                }
                                                {
                                                    (bet.type === "pickThree") && (<img src={ChampionPNG} alt="" style={{ width: "40px" }} />)
                                                }
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <span>
                                                    {cnNameMap[bet.type as string]} {cnNameMap[bet.subType]}
                                                    {
                                                        bet.playType === PlayType.PublishFollowBuy && (
                                                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>发单</span>
                                                        )
                                                    }
                                                    {
                                                        bet.playType === PlayType.FollowBuy && (
                                                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>跟单</span>
                                                        )
                                                    }
                                                    {
                                                        bet.playType === PlayType.GroupBuy && (
                                                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>合买</span>
                                                        )
                                                    }
                                                    {
                                                        bet.playType === PlayType.ChaseNum && (
                                                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>追号</span>
                                                        )
                                                    }
                                                    {
                                                        bet.playType === PlayType.BonusOptimization && (
                                                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>奖金优化</span>
                                                        )
                                                    }
                                                    {
                                                        bet.playType === PlayType.Normal && (
                                                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>自购</span>
                                                        )
                                                    }
                                                </span> <br />
                                                <span>下单用户：{username}</span> <br />
                                                <span>下单时间：{getTime(userBuyTime)}</span> <br />
                                                <span style={{ color: "red" }}>代购金额：{bet.spend?.toFixed(2)}元 </span> <br />
                                                <span style={{ color: "red" }}>派奖金额：{bet.bonus?.toFixed(2)}元 </span> <span style={{ color: "#b8b871", border: "solid 1px #b8b871", borderRadius: "5px", padding: "0 5px" }} onClick={() => navigate(`/manager/bet/detail/${bet._id}`)}>修改奖金</span> <br />
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginLeft: "10px",
                                            }}>
                                                <button style={{
                                                    display: "inline-block",
                                                    width: "50px",
                                                    height: "30px",
                                                    background: "#008ae6",
                                                    color: "white",
                                                    borderRadius: "5px"
                                                }}
                                                    onClick={() => navigate(`/manager/bet/detail/${bet._id}`)}
                                                >派奖</button>
                                                { isCrossDay && (<div style={{ color: "red" }}>【隔天单】</div>) }
                                            </div>
                                        </div>
                                    </Checkbox>
                                )
                            })
                        }
                    </Checkbox.Group>
                </Space>
            </div>
            <div style={{ flex: "0 1" }}>
                <div style={{
                    display: "flex",
                }}>
                    <Button style={{ width: "100px" }} onClick={handleSelectAll}>全选</Button>
                    <Button color='primary' style={{ float: "right", flex: "1 1" }} onClick={handlePayTicketsClick}>派奖</Button>
                </div>
            </div>
        </div>
    )
}