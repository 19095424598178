import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { NavBar } from "antd-mobile";
import { QRCode } from "antd";
import BackgroundJPG from "../../assets/back.c0b42344.jpg";
import "./ShareShop.css";

export default function ShareShop() {
    const shareUrl = useMemo(() => {
        const baseUrl = window.location.origin;
        return `${baseUrl}/register.html`;
    }, []);
    const navigate = useNavigate();
    const width = useMemo(() => (document.body&&document.body.clientWidth||document.getElementsByTagName("html")[0].offsetWidth)/ 3, [])

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
        }}>
            <NavBar onBack={() => navigate(-1)}>
                邀请店主
            </NavBar>
            <div className="com">
                <img src={BackgroundJPG} alt="" style={{ width:"100%" }}/>
                <div className="dhzo">
                    <div className="map">
                        <div className="shop-qrcode">
                            <QRCode value={shareUrl} size={width}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}