import React, { useEffect, useMemo, useState } from "react";
import "./MatchBetDetail.css";
import { Team } from "../interface/index.ts";

interface Props {
    bet: any,
}

export default function ChampionBetDetail(props: Props) {
    const { bet } = props;
    const items = useMemo(() => {
        return bet.items;
    }, [bet]);
    const times = useMemo(() => {
        return bet.times;
    }, [bet]);
    const teamList = useMemo(() => {
        return bet.championInfo?.teamList || [];
    }, [bet]);
    const oddsForBonus = useMemo(() => {
        return bet.championInfo?.oddsForBonus || {};
    }, [bet]);
    const [maxBonus, setMaxBonus] = useState<string>();
    useEffect(() => {
        let maxBonus = 0;
        for(const team of teamList) {
            const odds = oddsForBonus[team._id] || team.odds;
            maxBonus = Math.max(maxBonus, odds);
        }
        setMaxBonus((maxBonus * times * 2).toFixed(2));
    }, [items, times, oddsForBonus, teamList])
    return (
        <div className="match-table item">
            <div className="top">
                <span style={{ marginLeft: "7px", fontWeight: 900, marginRight: "5px", flexShrink: 0 }}>投注信息</span>
                <div className="left" style={{ overflow: "hidden" }}>
                    <span>
                        <span style={{ display: "flex", flexFlow: "row", alignItems: "center", justifyContent: "center" }}>
                            <span className="item_value item_value4 item_class" style={{ flexShrink: 0 }}>{times}倍</span>
                        </span>
                    </span>
                </div>
                <div className="right"></div>
            </div>
            <div className="container">
                <div style={{ display: "flex", width: "100%", height: "29px", textAlign: "center", lineHeight: "29px", backgroundColor: "rgb(223, 223, 223)", fontSize: "13px" }}>
                    <div style={{ width: "20%", border: "1px solid rgb(240, 243, 246)" }}>编号</div>
                    <div style={{ width: "30%", border: "1px solid rgb(240, 243, 246)" }}>冠军/冠亚军</div>
                    <div style={{
                        width: "20%",
                        border: "1px solid rgb(240, 243, 246)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}>(赔率)</div>
                    <div style={{ width: "30%", border: "1px solid rgb(240, 243, 246)" }}>结果</div>
                </div>
                {
                    teamList?.length > 0 && teamList.map((team: Team) => {
                        const teamId = team._id;
                        const odds = oddsForBonus[teamId] || team.odds;
                        const selectionNum = team.selectionNum;
                        return (
                            <div key={teamId}>
                                <div style={{ display: "flex", width: "100%", fontSize: "13px", minHeight: "34px", textAlign: "center" }}>
                                    <div style={{ width: "20%" }}>
                                        ({selectionNum})
                                    </div>
                                    <div style={{ width: "30%", height: "100%" }}>
                                        {team.cnName}
                                    </div>
                                    <div style={{ width: "20%", height: "100%" }}>
                                        {odds.toFixed(2)}
                                    </div>
                                    <div style={{ width: "30%", height: "100%" }}>
                                        ---
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div>
                    <div style={{ textAlign: "center", width: "100%", height: "43px", lineHeight: "43px" }}>
                        预计最高奖金：{maxBonus}元
                        <div data-v-e4d6d362="" className="u-icon u-icon--right" style={{ margin: "0px 10px" }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}