import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Mask, NavBar } from "antd-mobile";
import { getManagerAgentUsers } from "../../axios";
import { getOneDayStart, getOneDayEnd, getDay, getTime } from "../../utils";
import DatePicker from "../../components/DatePicker";
import "./AgentStatistics.css";
type resultItem = {
    _id: string,
    remark: string;
    username: string,
    phone: string,
    balance: number,
    createTime: number,
    amount: number,
}

export default function AgentStatistics() {
    const navigate = useNavigate();
    const params = useParams();
    const agentId = useMemo(() => params.id, [params.id]);
    const now = useMemo(() => Date.now(), []);
    const [result, setResult] = useState<resultItem[]>([]);
    const [startTime, setStartTime] = useState(getOneDayStart(now));
    const [endTime, setEndTime] = useState(getOneDayEnd(now));
    const [totalAmount, setTotalAmount] = useState(0);
    const [datePickerStartVisible, setDatePickerStartVisible] = useState(false);
    const [datePickerEndVisible, setDatePickerEndVisible] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    useEffect(() => {
        getManagerAgentUsers({ startTime, endTime, agentId }).then(({data}) => {
            const { result = [] } = data;
            setResult(result);
            let _totalAmount = 0;
            for(let item of result) {
                _totalAmount += item.amount;
            };
            setTotalAmount(_totalAmount);
        })
    }, [startTime, endTime])
    const back = () => {
        navigate(-1);
    }
    return (
        <div className="agent-my-users">
            <NavBar onBack={back}>
                用户统计
            </NavBar>
            <div className="topBox">
                <div className="top">
                    <div className="dataBox" onClick={() => setDatePickerStartVisible(true)}>
                        <div>
                            <div className="uni-input">{ getDay(startTime) }</div>
                        </div>
                    </div>
                    <span><span>至</span></span>
                    <div className="dataBox" onClick={() => setDatePickerEndVisible(true)}>
                        <div><div className="uni-input">{ getDay(endTime) }</div></div>
                    </div>
                </div>
                <Mask visible={datePickerStartVisible} onMaskClick={() => setDatePickerStartVisible(false)}>
                    <div>
                        <DatePicker defaultTime={startTime} onChange={(time, precision) => {
                            setDatePickerStartVisible(false);
                            if(precision === "day") {
                                setStartTime(getOneDayStart(time));
                            }
                        }} />
                    </div>
                </Mask>
                <Mask visible={datePickerEndVisible} onMaskClick={() => setDatePickerEndVisible(false)}>
                    <div>
                        <DatePicker defaultTime={startTime} onChange={(time, precision) => {
                            setDatePickerEndVisible(false);
                            if(precision === "day") {
                                setEndTime(getOneDayEnd(time));
                            }
                        }} />
                    </div>
                </Mask>
                <div className="box">
                    <div className="left d">
                        <div className={`title ${activeTab === 1 && "active"}`} onClick={() => setActiveTab(1)}>注册数据</div>
                    </div>
                    <div className="right d">
                        <div className={`title ${activeTab === 2 && "active"}`} onClick={() => setActiveTab(2)}>投注数据</div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div>
                    <div className="title">投注金额：{totalAmount}元</div>
                    <div className="cont">
                        <div className="t1">用户名</div>
                        <div className="t2">余额</div>
                        {
                            activeTab === 1 && (
                                <div className="t3">注册时间</div>
                            )
                        }
                        {
                            activeTab === 2 && (
                                <div className="t3">投注金额</div>
                            )
                        }
                        <div className="t4">投注详情</div>
                    </div>
                    <div>
                        {
                            result.map((item) => {
                                return (
                                    <div className="item">
                                        <div className="t1">
                                            <p>{ item.remark || item.username }</p>
                                            <p style={{ "marginTop": "10px", "marginRight": "5px" }}>
                                                {item.phone}
                                            </p>
                                        </div>
                                        <div className="t2">
                                            <p className="fee">{item.balance?.toFixed(2)}元</p>
                                        </div>
                                        {
                                            activeTab === 1 && (
                                                <div className="t3">
                                                    <p>{ getTime(item.createTime)}</p>
                                                </div>
                                            )
                                        }
                                        {
                                            activeTab === 2 && (
                                                <div className="t3 fee">
                                                    <p>{item.amount?.toFixed(2)}元</p>
                                                </div>
                                            )
                                        }
                                        <div className="t4">
                                            <span className="checkBtn" onClick={() => {navigate("/manager/user/betlist", { state: {id: item._id } })}}>查看</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}