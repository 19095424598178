import React, { useMemo } from "react";
import { List, NavBar } from "antd-mobile";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function BetManagement() {
    const navigate = useNavigate();
    const shop = useSelector((state) => state.shop?.shop || {});
    const pickThreeConfig = useMemo(() => {
        return shop.config?.["pickThree"] || {}
    }, [shop]);
    const beijingConfig = useMemo(() => {
        return shop.config?.["beijing"] || {}
    }, [shop]);
    const footballConfig = useMemo(() => {
        return shop.config?.["football"] || {}
    }, [shop]);
    const basketballConfig = useMemo(() => {
        return shop.config?.["basketball"] || {}
    }, [shop]);
    const championConfig = useMemo(() => {
        return shop.config?.["champion"] || {}
    }, [shop]);
    return (
        <div>
            <NavBar onBack={() => navigate(-1)}>
                彩种管理
            </NavBar>
            <List header='玩法开启'>
                {
                    pickThreeConfig.adminStatus && (
                        <List.Item clickable
                            onClick={() => navigate("/manager/bet/setting", { state: { type: "pickThree", config: pickThreeConfig } })}>
                            <div>排列三</div>
                            <div>
                                {pickThreeConfig.enableBuy ? "出票" : "禁售"}&nbsp;
                                最低投注{pickThreeConfig.minAmount}元&nbsp;
                                停售前{pickThreeConfig.earlyCloseTime / (60 * 1000)}分钟截止投注
                            </div>
                        </List.Item>
                    )
                }
                {
                    beijingConfig.adminStatus && (
                        <List.Item clickable 
                            onClick={() => navigate("/manager/bet/setting", { state: { type: "beijing", config: beijingConfig } })}>
                            <div>北京单场</div>
                            <div>
                                {beijingConfig.enableBuy ? "出票" : "禁售"}&nbsp;
                                最低投注{beijingConfig.minAmount}元&nbsp;
                                停售前{beijingConfig.earlyCloseTime / (60 * 1000)}分钟截止投注
                            </div>
                        </List.Item>
                    )
                }
                {
                    footballConfig.adminStatus && (
                        <List.Item clickable
                            onClick={() => navigate("/manager/bet/setting", { state: { type: "football", config: footballConfig } })}>
                            <div>竞彩足球</div>
                            <div>
                                {footballConfig.enableBuy ? "出票" : "禁售"}&nbsp;
                                最低投注{footballConfig.minAmount}元&nbsp;
                                停售前{footballConfig.earlyCloseTime / (60 * 1000)}分钟截止投注
                            </div>
                        </List.Item>
                    )
                }
                {
                    basketballConfig.adminStatus && (
                        <List.Item clickable
                            onClick={() => navigate("/manager/bet/setting", { state: { type: "basketball", config: basketballConfig } })}>
                            <div>竞彩篮球</div>
                            <div>
                                {basketballConfig.enableBuy ? "出票" : "禁售"}&nbsp;
                                最低投注{basketballConfig.minAmount}元&nbsp;
                                停售前{basketballConfig.earlyCloseTime / (60 * 1000)}分钟截止投注
                            </div>
                        </List.Item>
                    )
                }
                {
                    championConfig.adminStatus && (
                        <List.Item clickable
                            onClick={() => navigate("/manager/bet/setting", { state: { type: "champion", config: championConfig } })}>
                            <div>冠亚军</div>
                            <div>
                                {championConfig.enableBuy ? "出票" : "禁售"}&nbsp;
                                最低投注{championConfig.minAmount}元&nbsp;
                                停售前{championConfig.earlyCloseTime / (60 * 1000)}分钟截止投注
                            </div>
                        </List.Item>
                    )
                }
            </List>
        </div>
    )
}