// 足球
// 胜平负：had
// 让球胜平负：hhad 
// 比分：crs
// 总进球数: ttg
// 半全场胜平负：hafu
// 篮球
// 胜负: mnl
// 让分胜负: hdc
// 胜分差：wnm
// 大小分：hilo
// eslint-disable-next-line import/no-anonymous-default-export
export const cnNameMap:{ [key:string]: string } = {
    football: "竞彩足球",
    had: "胜平负",
    hhad: "让球胜平负",
    hhad_win: "让胜",
    hhad_draw: "让平",
    hhad_lose: "让负",
    win: "胜",
    draw: "平",
    lose: "负",
    crs: "比分",
    s01s00: "1:0",
    s02s00: "2:0",
    s02s01: "2:1",
    s03s00: "3:0",
    s03s01: "3:1",
    s03s02: "3:2",
    s04s00: "4:0",
    s04s01: "4:1",
    s04s02: "4:2",
    s05s00: "5:0",
    s05s01: "5:1",
    s05s02: "5:2",
    s00s00: "0:0",
    s01s01: "1:1",
    s02s02: "2:2",
    s03s03: "3:3",
    s00s01: "0:1",
    s00s02: "0:2",
    s01s02: "1:2",
    s00s03: "0:3",
    s01s03: "1:3",
    s02s03: "2:3",
    s00s04: "0:4",
    s01s04: "1:4",
    s02s04: "2:4",
    s00s05: "0:5",
    s01s05: "1:5",
    s02s05: "2:5",
    s1sw: "胜其他",
    s1sd: "平其他",
    s1sl: "负其他",
    ttg: "总进球数",
    s0: '0球',
    s1: '1球',
    s2: '2球',
    s3: '3球',
    s4: '4球',
    s5: '5球',
    s6: '6球',
    s7: '7+球',
    hafu: "半全场胜平负",
    ww: "胜胜",
    wd: "胜平",
    wl: "胜负",
    dw: "平胜",
    dd: "平平",
    dl: "平负",
    lw: "负胜",
    ld: "负平",
    ll: "负负",
    basketball: "竞彩篮球",
    mnl: "胜负",
    mnl_lose: "客胜",
    mnl_win: "主胜",
    hdc: "让分胜负",
    hdc_lose: "让客胜",
    hdc_win: "让主胜",
    wnm: "胜分差",
    hilo: "大小分",
    w1: "主胜1-5",
    w2: "主胜6-10",
    w3: "主胜11-15",
    w4: "主胜16-20",
    w5: "主胜21-25",
    w6: "主胜26+",
    l1: "客胜1-5",
    l2: "客胜6-10",
    l3: "客胜11-15",
    l4: "客胜16-20",
    l5: "客胜21-25",
    l6: "客胜26+",
    high: "大分",
    low: "小分",
    "1^1": "单关",
    "2^1": "2串1",
    "3^1": "3串1",
    "4^1": "4串1",
    "5^1": "5串1",
    "6^1": "6串1",
    "7^1": "7串1",
    "8^1": "8串1",
    "9^1": "9串1",
    "10^1": "10串1",
    "11^1": "11串1",
    "12^1": "12串1",
    "13^1": "13串1",
    "14^1": "14串1",
    "15^1": "15串1",
    "3^3": "3串3",
    "3^4": "3串4",
    "4^4": "4串4",
    "4^5": "4串5",
    "4^6": "4串6",
    "4^11": "4串11",
    "5^5": "5串5",
    "5^6": "5串6",
    "5^10": "5串10",
    "5^16": "5串16",
    "5^20": "5串20",
    "5^26": "5串26",
    "6^6": "6串6",
    "6^7": "6串7",
    "6^15": "6串15",
    "6^20": "6串20",
    "6^22": "6串22",
    "6^35": "6串35",
    "6^42": "6串42",
    "6^50": "6串50",
    "6^57": "6串57",
    "7^7": "7串7",
    "7^8": "7串8",
    "7^21": "7串21",
    "7^35": "7串35",
    "7^120": "7串120",
    "8^8": "8串8",
    "8^9": "8串9",
    "8^28": "8串28",
    "8^56": "8串56",
    "8^70": "8串70",
    "8^247": "8串247",
    pickThree: "排列三",
    direct: "直选",
    "101": "定位复式",
    "102": "组合复式",
    "103": "和值",
    "104": "组合胆拖",
    "105": "跨度复式",
    group3: "组三",
    "301": "单式",
    "302": "复式",
    "303": "胆拖",
    "304": "跨度复式",
    group6: "组六",
    "601": "复式",
    "602": "胆拖",
    "603": "跨度复式",
    beijing: "北京单场",
    sxds: "上下单双",
    sd: "上单",
    ss: "上双",
    xd: "下单",
    xs: "下双",
    mixed: "混合投注",
    champion: "欧洲杯",
    chp: "冠军游戏",
    fnl: "冠亚军游戏"
}
export const status2CN: { [key:string]: string } = {
    "0": "未接单",
    "1": "未出票",
    "2": "已出票",
    "3": "未中奖",
    "4": "已中奖",
    "5": "已派奖",
    "6": "已撤单",
    "7": "合买中",
}

export const action2CN: { [key:string]: string } = {
    "add": "人工加款",
    "sub": "人工扣款",
    "bonus": "返奖",
    "order": "下单",
    "recall": "退款",
    "commission": "佣金",
    'draw': "提现",
    'charge': '线上充值',
    "turnout": "转出至",
    "turnin": "转入自",
}

export const betStatus2CN: { [key:string]: string } = {
    "0,1": "待出票",
    "1": "待出票",
    "2": "待开奖",
}