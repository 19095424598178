import React, { useState, useEffect, useMemo } from "react";
import GoEasy from 'goeasy';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getGoEasyClient, connectGoEasyClient } from "../../utils/getIm";
import {getTime} from "../../utils/index";
import "./Conversations.css"
import { NavBar } from "antd-mobile";
export default function Converstions() {
    const shop = useSelector((state) => state.shop.shop || {});
    const navigate = useNavigate();
    const goEasy = useMemo(() => getGoEasyClient(), []);
    const [conversations, setConversations] = useState([]);
    
    useEffect(() => {
        const loadConversations = function () {
            goEasy.im.latestConversations({
                onSuccess: (result) => {
                    console.log(result.content.conversations);
                    setConversations(result.content.conversations);
                },
                onFailed: (error) => {
                    console.log('获取最新会话列表失败, code:' + error.code + 'content:' + error.content);
                },
            });
        }
        const listenConversationUpdate = function () {
            // 监听会话列表变化
            goEasy.im.on(GoEasy.IM_EVENT.CONVERSATIONS_UPDATED, setConversations);
        }
        const initIM = async() => {
            await connectGoEasyClient(goEasy, shop);
            loadConversations();
            listenConversationUpdate();
        }
        initIM();
    }, [goEasy, shop])
    return (
        <div className="conversations">
            <NavBar onBack={() => navigate(-1)}>
                用户消息
            </NavBar>
            <div className="conversation-list">
                <div className="conversation-list-container">
                    <div className="conversation-list-content">
                        {
                            conversations && conversations.length ? (
                                <div>
                                    {
                                        conversations && conversations.map((conversation) => {
                                            const lastMessage = conversation.lastMessage
                                            const selfMessage = lastMessage.senderId === shop._id;
                                            return (
                                                <div className="conversation" onClick={() => {
                                                    if(conversation.type === "group") {
                                                        navigate("/manager/chat/group")
                                                    } else {
                                                        navigate("/manager/chat/private", { state: { id: conversation.userId, name: conversation.data.name, avatar: conversation.data.avatar } })}
                                                    }
                                                }>
                                                    <div className="avatar">
                                                        <img src={conversation.data.avatar} alt={conversation.data.name}/>
                                                        {
                                                            conversation.unread > 0 && (
                                                                <div className="unread-count">
                                                                    <span className="unread">{ conversation.unread }</span>
                                                                </div>
                                                            ) 
                                                        }
                                                    </div>
                                                    <div className="conversation-message">
                                                        <div className="conversation-top">
                                                            <span className="conversation-name">{ conversation.data.name }</span>
                                                            <div className="conversation-time">
                                                                <div>{ getTime(lastMessage.timestamp) }</div>
                                                            </div>
                                                        </div>
                                                        <div className="conversation-bottom">
                                                            <div className="conversation-content">
                                                                {
                                                                    lastMessage.read === false && selfMessage && (<div className="unread-text" > [未读] </div>)
                                                                }
                                                                {
                                                                    conversation.type === 'private' ? (
                                                                        <div>
                                                                            { selfMessage ? '我' : conversation.data.name }:
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            { selfMessage ? '我' : lastMessage.senderData.name }:
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    lastMessage.type === 'text' && (<span className="text">{ lastMessage.payload.text }</span>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <div className="no-conversation">- 当前没有会话 -</div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}