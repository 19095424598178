import React, { useEffect, useRef, useState } from "react";
import { NavBar, Tabs, InfiniteScroll, Toast } from "antd-mobile";
import { getShopAction } from "../../axios/index.js";
import { getTime } from "../../utils/index.ts";
import { useNavigate } from "react-router-dom";
import "./ShopActionlist.css";
const addBalanceAction = ["charge", "commission"];
const subBalanceAction = ["commissionPay", "serviceFee"];
const action2CN: { [key: string]: string } = {
    'charge': '充值',
    "commission": "跟单店主佣金",
    "commissionPay": "跟单佣金店主代扣",
    "serviceFee": "服务费",
}
export default function UserDetails() {
    const navigate = useNavigate();
    const [actionList, setActionList] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const pagenation = useRef({ pageNum: 0, loading: false });
    const [currentTab, setCurrentTab] = useState('charge,commission,commissionPay,serviceFee');
    
    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            action: currentTab,
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };

        try {
            const { data } = await getShopAction(query);
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                pagenation.current.pageNum++;
                setActionList([...actionList, ...data.result]);
                setHasMore(data.result.length === 10);
            }
        } catch (e) {
            Toast.show("获取彩票失败");
        }
        pagenation.current.loading = false;
    }

    useEffect(() => {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        setActionList([]);
        pagenation.current.pageNum = 0;
        let query: any = {
            action: currentTab,
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        getShopAction(query).then(({ data }: any) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                pagenation.current.pageNum++;
                setActionList(data.result);
                setHasMore(data.result.length === 10);
            }
        }).finally(() => {
            pagenation.current.loading = false;
        })
    }, [currentTab]);

    const back = () => {
        navigate(-1);
    }
    return (
        <div className="shop-action-list">
            <NavBar onBack={back}>
                账户明细
            </NavBar>
            <Tabs onChange={setCurrentTab}>
                <Tabs.Tab title='全部' key='charge,commission,commissionPay,serviceFee'>
                </Tabs.Tab>
                <Tabs.Tab title='收入' key='charge,commission'>
                </Tabs.Tab>
                <Tabs.Tab title='支出' key='commissionPay,serviceFee'>
                </Tabs.Tab>
            </Tabs>
            <div className="content">
                {
                    actionList.map((action: any) => {
                        return (
                            <div className="item" onClick={() => navigate(`/manager/bet/detail/${action.betId}`)}>
                                <div className="left">
                                    <div className="d1">
                                        { action.action !== 'serviceFee' && `(${action.userId?.remark || action.userId?.username})` }{action2CN[action.action]}
                                    </div>
                                    
                                    {getTime(action.time)}
                                </div>
                                <div className="right">
                                    {
                                        addBalanceAction.includes(action.action) && (<div className="d1">+{action.balanceChanges?.toFixed(2)}元</div>)
                                    }
                                    {
                                        subBalanceAction.includes(action.action) && (<div className="d1">-{action.balanceChanges?.toFixed(2)}元</div>)
                                    }
                                    <div>{action.balance.toFixed(2)}</div>
                                </div>
                            </div>
                        )
                    })
                }
                <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />
            </div>
        </div>
    )
}

