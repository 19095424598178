import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavBar, Toast, Image, List, Button, Empty, Mask, Radio, Space, Input, Form } from "antd-mobile";
import copy from "copy-to-clipboard";
import { getManagerDrawInfo, getManagerDrawList, postManagerDrawFinish, postManagerDrawInfo } from "../../axios";
import { getTime } from "../../utils";
import DefaultUser from "../../assets/default_photo.8b581359.png";
const way2CN = {
    "alipay": "支付宝",
    "bank": "银行卡",
}

export default function DrawList() {
    const navigate = useNavigate();
    const [drawList, setDrawList] = useState([]);
    const [status, setStatus] = useState('confirm');
    const [refuseReason, setRefuseReason] = useState('');
    const [currentDrawId, setCurrentDrawId] = useState(null);
    const [maskVisible, setMaskVisible] = useState(false);
    const [drawInfoVisible, setDrawInfoVisible] = useState(false);
    const [minAmount, setMinAmount] = useState('10');

    const getDrawList = () => {
        getManagerDrawList({}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setDrawList(data.result || []);
            }
        })
    }

    const handleChangeDrawInfo = () => {
        setDrawInfoVisible(false);
        postManagerDrawInfo({ minAmount }).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                Toast.show("更改成功");
            }
        })
    }

    useEffect(() => {
        getDrawList();
        getManagerDrawInfo({}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setMinAmount(data.info.minAmount);
            }
        })
    }, []);

    const handleDrawStatusChanged = () => {
        Toast.show('请求中');
        postManagerDrawFinish({drawId: currentDrawId, status: status === 'confirm' ? 1 : 2, refuseReason}).then(({data}) => {
            setMaskVisible(false);
            setRefuseReason('');
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                getDrawList();
            }
        })
    }

    return (
        <div>
            <NavBar onBack={() => navigate(-1)} right={<div style={{ display: "flex", gap: '10px' }}><div onClick={() => setDrawInfoVisible(!drawInfoVisible)}>提现设置</div><div onClick={() => navigate("/manager/draw/history")}>提现历史</div></div>}>
                提现申请
            </NavBar>
            {
                drawInfoVisible && (
                    <div>
                        <Form layout='horizontal'>
                            <Form.Item
                                label='最低提款金额'
                                extra={
                                    <Button onClick={handleChangeDrawInfo}>确认</Button>
                                }
                            >
                                <Input placeholder='请输入用户最低提款金额' value={minAmount} onChange={setMinAmount} type="number" clearable />
                            </Form.Item>
                        </Form>
                    </div>
                )
            }
            <div>
                {
                    drawList && drawList.length ? (
                        <List>
                            { drawList.map((drawInfo) => {
                                return (
                                    <List.Item clickable onClick={() => { setCurrentDrawId(drawInfo._id); setMaskVisible(true) }}>
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                    <div>
                                                        <Image src={drawInfo.user.avatar || DefaultUser} width={50} height={50} fit='fill' />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            {drawInfo.realName}
                                                            <Button color='primary' fill='none' onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.nativeEvent.stopImmediatePropagation();
                                                                copy(drawInfo.realName)
                                                            }}>
                                                                复制
                                                            </Button>
                                                            <br />
                                                            (昵称: { drawInfo.user.remark || drawInfo.user.username })
                                                            <Button color="primary" fill="none" onClick={() => {
                                                                navigate("/manager/user/actionlist", { state: { id: drawInfo.user._id } })
                                                            }}>查看明细</Button>
                                                        </div>
                                                        <div>{ getTime(drawInfo.createTime) }</div>
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: "24px", fontWeight: "bolder" }}>{drawInfo.amount}</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                alignItems: "end",
                                                flexDirection: "column"
                                            }}>
                                                <div>渠道：{way2CN[drawInfo.way]}</div>
                                                {
                                                    drawInfo.way === "alipay" && (
                                                        <div>
                                                            支付宝账号：{drawInfo.alipayAcount}
                                                            <Button color='primary' fill='none' onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.nativeEvent.stopImmediatePropagation();
                                                                copy(drawInfo.alipayAcount)
                                                            }}>
                                                                复制
                                                            </Button>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    drawInfo.way === "bank" && (
                                                        <>
                                                            <div>开户行：{drawInfo.bankName}</div>
                                                            <div>
                                                                银行卡号：{drawInfo.bankCard}
                                                                <Button color='primary' fill='none' onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.nativeEvent.stopImmediatePropagation();
                                                                    copy(drawInfo.bankCard)
                                                                }}>
                                                                    复制
                                                                </Button>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </List.Item>
                                )
                            }) }
                        </List>
                    ) : (
                        <Empty description='暂无数据' />
                    )
                }
            </div>
            <Mask visible={maskVisible}>
                <div style={{
                    width: '100vw',
                    height: '100vh',
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <div style={{
                        width: '60vw',
                        height: '40vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                    }}>
                        <div style={{
                            padding: '10px'
                        }}>
                            <Radio.Group defaultValue='confirm' onChange={(val) => setStatus(val as string)}>
                                <Space direction='vertical'>
                                    <Radio value='confirm'>受理通过</Radio>
                                    <Radio value='refuse'>拒绝</Radio>
                                </Space>
                            </Radio.Group>
                            {
                                status === 'refuse' && (<Input
                                    placeholder='请输入拒绝原因'
                                    style={{ marginTop: '10px' }}
                                    value={refuseReason}
                                    onChange={(val) => setRefuseReason(val as string)}
                                />)
                            }
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <Button color="success" block onClick={handleDrawStatusChanged}>确定</Button>
                            <Button color="danger" block onClick={() => { setMaskVisible(false); setRefuseReason('') } }>取消</Button>
                        </div>
                    </div>
                </div>
            </Mask>
        </div>
    )
}