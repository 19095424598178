import React from "react";
import { cnNameMap } from "../constant/cnNameMap";
import "./PickThreeBetDetail.css";

interface Props {
    items: [{
        type: string,
        subType: string,
        notes: number,
        times: number,
        target: string
    }],
    pickthreeInfo?: {
        pickthree: { finished: boolean, result: string }
        term: string,
    };
    detailVisible?: boolean,
    showTopInfo?: boolean,
}

export default function PickThreeBetDetail(props: Props) {
    const { pickthreeInfo, items, detailVisible = true, showTopInfo = true } = props;
    return (
        <div className="pick-three-table item">
            {
                showTopInfo && (
                    <div className="top">
                        <span style={{ marginLeft: "9px", fontWeight: 900, marginRight: "7px" }}>投注信息</span>
                        <div style={{
                            width: "109px",
                            height: "32px",
                            border: "1px solid rgb(119, 166, 235)",
                            textAlign: "center",
                            lineHeight: "32px",
                            color: "rgb(119, 166, 235)",
                            borderRadius: "5px"
                        }}>第{pickthreeInfo?.term}期</div>
                        <div className="left"><span></span></div>
                        <div className="right"></div>
                    </div>
                )
            }
            <div className="content">
                <div className="dltDetail">
                    <table>
                        <tr>
                            <th>玩法</th>
                            <th>投注</th>
                            <th>倍数金额</th>
                        </tr>
                        {
                            detailVisible && items?.map((item) => {
                                const { type, subType, notes, times, target } = item;
                                const [targetArrHundrend, targetArrTen, targetArrBit] = target.split("|").map((target) => target.split(","));
                                return (
                                    <tr className="">
                                        <td style={{ whiteSpace: "nowrap", marginRight: "10px" }}>{cnNameMap[type]}{cnNameMap[subType]}</td>
                                        <td className="">
                                            {
                                                subType === "101" && (
                                                    <>
                                                        <span>
                                                            {targetArrHundrend?.map(target => (
                                                                <span className="">
                                                                    <span>{target}</span>
                                                                </span>
                                                            ))}
                                                            <span className="" style={{ "marginLeft": "11px" }}>
                                                                <span>|</span>
                                                            </span>
                                                        </span>
                                                        <span>
                                                            {targetArrTen?.map(target => (
                                                                <span className="">
                                                                    <span>{target}</span>
                                                                </span>
                                                            ))}
                                                            <span className="" style={{ "marginLeft": "11px" }}>
                                                                <span>|</span>
                                                            </span>
                                                        </span>
                                                        <span>
                                                            {targetArrBit?.map(target => (
                                                                <span className="">
                                                                    <span>{target}</span>
                                                                </span>
                                                            ))}
                                                            <span className="show" style={{ "marginLeft": "11px", display: "none" }}>
                                                                <span>|</span>
                                                            </span>
                                                        </span>
                                                    </>
                                                )
                                            }
                                            {
                                                subType === "301" && (
                                                    <>
                                                        <span>
                                                            {targetArrTen?.map(target => (
                                                                <span className="">
                                                                    <span>{target}</span>
                                                                </span>
                                                            ))}
                                                            <span className="" style={{ "marginLeft": "11px" }}>
                                                                <span>|</span>
                                                            </span>
                                                        </span>
                                                        <span>
                                                            {targetArrTen?.map(target => (
                                                                <span className="">
                                                                    <span>{target}</span>
                                                                </span>
                                                            ))}
                                                            <span className="" style={{ "marginLeft": "11px" }}>
                                                                <span>|</span>
                                                            </span>
                                                        </span>
                                                        <span>
                                                            {targetArrBit?.map(target => (
                                                                <span className="">
                                                                    <span>{target}</span>
                                                                </span>
                                                            ))}
                                                            <span className="show" style={{ "marginLeft": "11px", display: "none" }}>
                                                                <span>|</span>
                                                            </span>
                                                        </span>
                                                    </>
                                                )
                                            }
                                            {
                                                ["104", "303", "602"].includes(subType) && (
                                                    <div>
                                                        {targetArrTen?.map(target => (
                                                            <span className="">
                                                                <span>{target}</span>
                                                            </span>
                                                        ))}
                                                        <span>
                                                            <span>#</span>
                                                        </span>
                                                        {targetArrBit?.map(target => (
                                                            <span className="">
                                                                <span>{target}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                )
                                            }
                                            {
                                                ["102", "103", "105", "301", "302", "304", "601", "603"].includes(subType) && (
                                                    <>
                                                        {targetArrBit?.map(target => (
                                                            <span className="">
                                                                <span>{target}</span>
                                                            </span>
                                                        ))}
                                                    </>
                                                )
                                            }
                                        </td>
                                        <td>
                                            <div>{times}倍</div>
                                            <div>{times * notes * 2}元</div>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </table>
                    {
                            !detailVisible && (
                                <div className="noShowClass">
                                    <div className="noShowClass-top">
                                        <div className="noShowClass-top-icon">
                                            
                                        </div>
                                        <div className="noShowClass-top-text">截止后公开</div>
                                    </div>
                                    <div className="noShowClass-bottom">预计最高回报: --- 倍</div>
                                </div>
                            )
                        }
                    {
                        pickthreeInfo?.pickthree?.finished && (
                            <p>
                                <div>
                                    <span style={{ fontWeight: 900 }}>开奖号码</span>
                                    <span style={{ fontSize: "13px", color: "rgb(153, 153, 153)" }}>（第{pickthreeInfo?.term}期）</span>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {
                                        pickthreeInfo?.pickthree?.result.split(",").map((num) => (
                                            <div className="c_red" style={{ marginRight: "6px", overflowWrap: "break-word", marginTop: "10px", width: "38px", height: "38px", border: "1px solid rgb(234, 232, 232)", borderRadius: "21px", textAlign: "center", lineHeight: "38px" }}>{num}</div>
                                        ))
                                    }
                                </div>
                            </p>
                        )
                    }
                </div>
            </div>
        </div>
    )
}