import React, { useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { List, NavBar, Popup, Stepper, Switch, Toast } from "antd-mobile";
import { cnNameMap } from "../../constant/cnNameMap";
import { postShopConfig } from "../../axios";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../store/shopSlice.ts";

const oneMinute = 60 * 1000;
export default function BetSetting() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const config = useMemo(() => {
        return location.state.config
    }, [location.state]);
    const type = useMemo(() => {
        return location.state.type
    }, [location.state]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(config.status);
    const [enableBuy, setEnableBuy] = useState(config.enableBuy);
    const [enableGroupBuy, setEnableGroupBuy] = useState(config.enableGroupBuy);
    const [enableFollowBuy, setEnableFollowBuy] = useState(config.enableFollowBuy);
    const [enableBonusOptimization, setEnableBonusOptimization] = useState(config.enableBonusOptimization);
    const [minAmount, setMinAmount] = useState(config.minAmount);
    const [earlyCloseTime, setEarlyCloseTime] = useState(config.earlyCloseTime);
    const [minAmountForUpdate, setMinAmountForUpdate] = useState(config.minAmount);
    const [earlyCloseTimeForUpdate, setEarlyCloseTimeForUpdate] = useState(config.earlyCloseTime / oneMinute);
    const [minAmountModalVisible, setMinAmountModalVisible] = useState(false);
    const [earlyCloseTimeModalVisible, setEarlyCloseTimeModalVisible] = useState(false);
    
    const handleUpdateConfig = (key: string, val: any) => {
        if(loading) return;
        setLoading(true);
        postShopConfig({type, key, val}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                dispatch(updateConfig({type, key, val}))
                if(key === "status") {
                    setStatus(val);
                }
                if(key === "enableBuy") {
                    setEnableBuy(val);
                }
                if(key === "enableGroupBuy") {
                    setEnableGroupBuy(val);
                }
                if(key === "enableFollowBuy") {
                    setEnableFollowBuy(val);
                }
                if(key === "enableBonusOptimization") {
                    setEnableBonusOptimization(val);
                }
                if(key === "minAmount") {
                    setMinAmount(val);
                }
                if(key === "earlyCloseTime") {
                    setEarlyCloseTime(val);
                }
            }
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <div>
            <NavBar onBack={() => navigate(-1)}>
                {cnNameMap[type]}管理
            </NavBar>
            <List>
                <List.Item 
                    extra={<Switch checked={status} onChange={(val) => handleUpdateConfig("status", val)} loading={loading}/>}
                    description='关闭后，用户无法看到此彩种，请谨慎操作！'>
                    彩种开关
                </List.Item> 
                <List.Item 
                    extra={<Switch checked={enableBuy} onChange={(val) => handleUpdateConfig("enableBuy", val)} loading={loading}/>}
                    description='关闭后，用户无法下单此彩种，请谨慎操作！'>
                    出票开关
                </List.Item>
                <List.Item 
                    extra={<Switch checked={enableGroupBuy} onChange={(val) => handleUpdateConfig("enableGroupBuy", val)} loading={loading}/>}
                    description='关闭后，用户将无法发起合买！'>
                    合买开关
                </List.Item>
                <List.Item 
                    extra={<Switch checked={enableFollowBuy} onChange={(val) => handleUpdateConfig("enableFollowBuy", val)} loading={loading}/>}
                    description='关闭后，用户将无法发单！'>
                    发单开关
                </List.Item>
                <List.Item 
                    extra={<Switch checked={enableBonusOptimization} onChange={(val) => handleUpdateConfig("enableBonusOptimization", val)} loading={loading}/>}>
                    奖金优化
                </List.Item>
                <List.Item
                    extra={`${earlyCloseTime / oneMinute}分钟`}
                    description={`用户需在官方截止时间前${earlyCloseTime / oneMinute}分钟下单`} 
                    onClick={() => !loading && setEarlyCloseTimeModalVisible(true)}
                    >
                    用户投注截止时间提前
                </List.Item>
                <List.Item 
                    extra={`${minAmount}元`}
                    description={`用户投注时低于此金额无法投注成功`}
                    onClick={() => !loading && setMinAmountModalVisible(true)}>
                    用户投注最低金额
                </List.Item>
            </List>
            {/* 截止投注时间 */}
            <Popup
              visible={earlyCloseTimeModalVisible}
              onMaskClick={() => {
                setEarlyCloseTimeModalVisible(false);
                setEarlyCloseTimeForUpdate(earlyCloseTime / oneMinute);
              }}
              bodyStyle={{ height: '20vh' }}
            >
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <div style={{ flex: "1 1", padding: "20px" }}>
                    <Stepper
                        value={earlyCloseTimeForUpdate}
                        step={1}
                        min={5}
                        max={30}
                        onChange={value => {
                            setEarlyCloseTimeForUpdate(value);
                        }}
                    />
                </div>
                <div style={{ display: "flex", height: "40px", alignItems: "center", textAlign: "center", width: "100%" }}>
                        <div style={{ flex: "1 1", height: "100%", lineHeight: "40px", color: "blue", background: "white" }} >
                            取消
                        </div>
                        <div style={{ flex: "1 1", height: "100%", lineHeight: "40px", background: "#8e8efd", color: "white" }}
                            onClick={() => { setEarlyCloseTimeModalVisible(false); handleUpdateConfig("earlyCloseTime", earlyCloseTimeForUpdate * oneMinute)}}>
                            确定
                        </div>
                    </div>
                </div>
            </Popup>
            {/* 最小下单 */}
            <Popup
              visible={minAmountModalVisible}
              onMaskClick={() => {
                setMinAmountModalVisible(false);
                setMinAmountForUpdate(minAmount);
              }}
              bodyStyle={{ height: '20vh' }}
            >
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <div style={{ flex: "1 1", padding: "20px" }}>
                    <Stepper
                        value={minAmountForUpdate}
                        step={2}
                        min={2}
                        onChange={value => {
                            if(value % 2 === 1) {
                                value = value+1;
                            }
                            setMinAmountForUpdate(value);
                        }}
                    />
                </div>
                <div style={{ display: "flex", height: "40px", alignItems: "center", textAlign: "center", width: "100%" }}>
                        <div style={{ flex: "1 1", height: "100%", lineHeight: "40px", color: "blue", background: "white" }} 
                            onClick={() => {
                                setMinAmountModalVisible(false);
                                setMinAmountForUpdate(minAmount)}
                            }>
                            取消
                        </div>
                        <div style={{ flex: "1 1", height: "100%", lineHeight: "40px", background: "#8e8efd", color: "white" }} 
                            onClick={() => { setMinAmountModalVisible(false); handleUpdateConfig("minAmount", minAmountForUpdate)}}>
                            确定
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    )
}