import React, { useState } from "react";
import { Button, Form, Input, NavBar, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { postShopEmployeeAdd } from "../../axios";

export default function EmployeeAdd() {
    const navigate = useNavigate();
    const [username, setUsername] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [passwordConfirm, setPasswordConfirm] = useState<string>();
    const handleSubmit = () => {
        if(!username) {
            Toast.show("用户名不能为空");
            return
        }
        if(!phone) {
            Toast.show("电话不能为空");
            return
        }
        if(!password) {
            Toast.show("密码不能为空");
            return
        }
        if(!passwordConfirm) {
            Toast.show("确认密码不能为空");
            return
        }
        if(passwordConfirm !== password) {
            Toast.show("请确认两次密码是否一致");
            return
        }
        postShopEmployeeAdd({
            username,phone,password
        }).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                navigate(-1);
            }
        })
    }
    return (
        <div>
            <NavBar onBack={() => navigate(-1)}>
                添加新员工
            </NavBar>
            <Form
                layout='horizontal'
                footer={
                    <Button block type='submit' color='primary' size='large' onClick={handleSubmit}>
                        提交
                    </Button>
                }
            >
                <Form.Item
                    name='username'
                    label='用户名'>
                    <Input onChange={setUsername} placeholder='请输入员工用户名' />
                </Form.Item>
                <Form.Item
                    name='phone'
                    label='手机号'>
                    <Input onChange={setPhone} placeholder='请输入员工用户名' />
                </Form.Item>
                <Form.Item
                    name='password'
                    label='密码'>
                    <Input onChange={setPassword} placeholder='请输入密码' />
                </Form.Item>
                <Form.Item
                    name='passwordConfirm'
                    label='确认密码'>
                    <Input onChange={setPasswordConfirm} placeholder='请再次输入密码' />
                </Form.Item>
            </Form>
        </div>
    )
}