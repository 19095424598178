import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  "_id": "",
  "username": "",
  "avatar": "",
  "phone": "",
  "balance": 0,
  "role": "",
  "agentKey": "",
  "createTime": 0
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {user: initialState},
  reducers: {
    set: (state, action) => {
      state.user = action.payload;
    },
    clear: (state) => {
      state = {user: initialState};
    },
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { set, clear } = userSlice.actions;

export default userSlice.reducer;