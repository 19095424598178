import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { InfiniteScroll, Mask, NavBar, Toast } from "antd-mobile";
import { getShopStatisticsAction } from "../../axios/index.js";
import { PlayType } from "../../constant/betPlayType.ts";
import { cnNameMap } from "../../constant/cnNameMap";
import { getDay, getOneDayEnd, getOneDayStart, getTime } from "../../utils/index.ts";
import DatePicker from "../../components/DatePicker.tsx";
import "./StaticsDetail.css";
import FootballPNG from "../../assets/407.png";
import BasketballPNG from "../../assets/406.png";
import BeijingPNG from "../../assets/405.png";
import PickThreePNG from "../../assets/202.png";
import ChampionPNG from "../../assets/champion.png";

const action2CN: {
    [key: string]: string
} = {
    ticket: "出票",
    bonus: "派奖",
    win: "获奖",
    add: "店主加款",
    sub: "店主扣款",
    charge: "线上充值"
}
export default function StaticsDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const actionListRef = useRef(null);
    const [scrollTop, setScrollTop] = useState(0);
    const action = useMemo(() => location.state?.action, [location.state?.action]);
    const _startTime = useMemo(() => location.state?.startTime, [location.state]);
    const _endTime = useMemo(() => location.state?.endTime, [location.state]);
    const now = useMemo(() => Date.now(), []);
    const [hasMore, setHasMore] = useState(true);
    const pagenation = useRef({ pageNum: 0, loading: false });
    const [startTime, setStartTime] = useState(_startTime || getOneDayStart(now));
    const [endTime, setEndTime] = useState(_endTime || getOneDayEnd(now));
    const [datePickerStartVisible, setDatePickerStartVisible] = useState(false);
    const [datePickerEndVisible, setDatePickerEndVisible] = useState(false);
    const [result, setResult] = useState<any[]>([]);
    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            startTime,
            endTime,
            action,
            pageNum: pagenation.current.pageNum,
        };
        try {
            const { data } = await getShopStatisticsAction(query);
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                pagenation.current.pageNum++;
                setResult([...result, ...data.result]);
                setHasMore(data.result.length === 20);
            }
        } catch (e) {
            Toast.show("获取订单失败");
        }
        pagenation.current.loading = false;
    }

    const handleActionClick = (id: string) => {
        setScrollTop(actionListRef.current?.scrollTop || 0);
        navigate(`/manager/bet/detail/${id}`)
    }

    const back = async() => {
        navigate(-1);
    }

    useEffect(() => {
        if(location.pathname === "/manager/statistics/action") {
            if(actionListRef) {
                setTimeout(() => {
                    actionListRef.current.scrollTop = scrollTop;
                }, 1)
            }
        }
    }, [location.pathname, scrollTop]);

    useEffect(() => {
        pagenation.current.pageNum = 0;
        setHasMore(true);
        setResult([]);
    }, [startTime, endTime])

    return (
        <div className="statics-detail" style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <NavBar onBack={back}>
                {action2CN[action]}
            </NavBar>
            <div className="topBox">
                <div className="top">
                    <div className="dataBox" onClick={() => setDatePickerStartVisible(true)}>
                        <div>
                            <div className="uni-input">{ getDay(startTime) }</div>
                        </div>
                    </div>
                    <span><span>至</span></span>
                    <div className="dataBox" onClick={() => setDatePickerEndVisible(true)}>
                        <div><div className="uni-input">{ getDay(endTime) }</div></div>
                    </div>
                </div>
            </div>
            <Mask visible={datePickerStartVisible} onMaskClick={() => setDatePickerStartVisible(false)}>
                <div>
                    <DatePicker defaultTime={startTime} onChange={(time, precision) => {
                        setDatePickerStartVisible(false);
                        if(precision === "day") {
                            setStartTime(getOneDayStart(time));
                        }
                    }} />
                </div>
            </Mask>
            <Mask visible={datePickerEndVisible} onMaskClick={() => setDatePickerEndVisible(false)}>
                <div>
                    <DatePicker defaultTime={endTime} onChange={(time, precision) => {
                        setDatePickerEndVisible(false);
                        if(precision === "day") {
                            setEndTime(getOneDayEnd(time));
                        }
                    }} />
                </div>
            </Mask>
            <div style={{ flex: "1 1 auto", overflowY: "auto", backgroundColor: "whitesmoke" }} ref={actionListRef}>
                {
                    result.map((item) => {
                        const { betId: bet = {}, action } = item;
                        return (
                            <div style={{
                                display: "flex", 
                                padding: "10px",
                                margin: "10px",
                                fontSize: "13px",
                                backgroundColor: "white",
                                borderRadius: "10px"
                            }}>
                                {
                                    ["add", "sub", 'charge'].includes(action) ? (
                                        <div>
                                            <span>用户：{ item.userId?.remark || item.userId?.username }</span> <br />
                                            <span>{action2CN[action]}: {item.balanceChanges.toFixed(2)}</span> <br />
                                            <span>操作时间：{ getTime(item.time) }</span>
                                        </div>
                                    ) : (
                                        <>
                                            <div style={{ marginRight: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {
                                                    (bet.type === "football") && (<img src={FootballPNG} alt="" style={{ width: "40px" }}/>)
                                                }
                                                {
                                                    (bet.type === "beijing") && (<img src={BeijingPNG} alt="" style={{ width: "40px" }}/>)
                                                }
                                                {
                                                    (bet.type === "basketball") && (<img src={BasketballPNG} alt="" style={{ width: "40px" }}/>)
                                                }
                                                {
                                                    (bet.type === "pickThree") && (<img src={PickThreePNG} alt="" style={{ width: "40px" }}/>)
                                                }
                                                {
                                                    (bet.type === "champion") && (<img src={ChampionPNG} alt="" style={{ width: "40px" }}/>)
                                                }
                                                
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <div>
                                                    <span>
                                                        {cnNameMap[bet.type as string]} {cnNameMap[bet.subType]}
                                                        {
                                                            bet.playType === PlayType.PublishFollowBuy && (
                                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>发单</span>
                                                            )
                                                        }
                                                        {
                                                            bet.playType === PlayType.FollowBuy && (
                                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>跟单</span>
                                                            )
                                                        }
                                                        {
                                                            bet.playType === PlayType.GroupBuy && (
                                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>合买</span>
                                                            )
                                                        }
                                                        {
                                                            bet.playType === PlayType.ChaseNum && (
                                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>追号</span>
                                                            )
                                                        }
                                                        {
                                                            bet.playType === PlayType.BonusOptimization && (
                                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>奖金优化</span>
                                                            )
                                                        }
                                                        {
                                                            bet.playType === PlayType.Normal && (
                                                                <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>自购</span>
                                                            )
                                                        }
                                                    </span>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div>
                                                        <span>下单用户：{ item.userId?.remark || item.userId?.username }</span> <br />
                                                        <span>出票时间：{ getTime(bet?.userBuyTime) }</span> <br />
                                                        <span style={{ color: "red" }}>代购金额：{bet?.spend?.toFixed(2)}元 </span> <br />
                                                        {
                                                            ["bonus", "win"].includes(action) && (<span style={{ color: "red" }}>派奖金额：{item.balanceChanges?.toFixed(2)}元 </span>)
                                                        }
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginLeft: "10px",
                                                        marginTop: '-20px',
                                                    }}>
                                                        <button style={{
                                                            display: "inline-block",
                                                            width: "70px",
                                                            height: "30px",
                                                            background: "#008ae6",
                                                            color: "white",
                                                            borderRadius: "5px"
                                                        }}
                                                        onClick={() => handleActionClick(bet._id)}
                                                        >查看</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        )
                    })
                }
                <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />

            </div>
        </div>
    )
}