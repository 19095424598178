import { Button, Form, Input, NavBar, Toast } from "antd-mobile";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getPhoneCaptcha, postShopPayPasswordChange } from "../../axios";
import { useSelector } from "react-redux";

export default function PayPassword() {
    const navigate = useNavigate();
    const shop = useSelector((state) => state.shop?.shop || {});
    const [phone, setPhone] = useState<string>(shop.ownerInfo?.phone);
    const [captcha, setCaptcha] = useState('');
    const [second, setSecond] = useState(0);
    const [payPassword, setPayPassword] = useState<string>();
    const [payPasswordVisible, setPayPasswordVisible] = useState(false);
    const [payPasswordConfirm, setPayPasswordConfirm] = useState<string>();
    const [payPasswordConfirmVisible, setPayPasswordConfirmVisible] = useState(false);

    useEffect(() => {
        if(second !== 0) {
            setTimeout(() => {
                setSecond(second-1);
            }, 1 * 1000)
        }
    }, [second]);
    const handleSendCaptcha = () => {
        const regu = /^1[3-9][0-9]{9}$/
        if(!regu.test(phone)) {
            Toast.show("请输入正确的手机号");
            return;
        }
        setSecond(60);
        getPhoneCaptcha({ type: "payPassword", phone }).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            }
        })
    }
    const handleSubmit = async() => {
        if(payPasswordConfirm !== payPassword) {
            Toast.show("两次密码不匹配");
            return;
        }
        const { data } = await postShopPayPasswordChange({ captcha, payPassword });
        if (data.status === 'error') {
            Toast.show(data.errorMessage);
        } else {
            Toast.show("支付密码修改成功！");
        }
    }
    return (
        <div>
            <NavBar
                onBack={() => navigate(-1)}>
                设置支付密码
            </NavBar>
            <Form layout='horizontal'>
                <Form.Item
                    label='手机号'
                    name='phone'>
                    <Input
                        placeholder='请输入手机号'
                        clearable
                        disabled={true}
                        defaultValue={phone}
                        onChange={(val) => setPhone(val)}/>
                </Form.Item>
                <Form.Item label='短信验证码' extra={
                    second === 0 ? <span onClick={handleSendCaptcha}>发送验证码</span> : <span>{second}s</span>
                }>
                    <Input placeholder='请输入' onChange={setCaptcha}/>
                </Form.Item>
                <Form.Item
                    label='新密码'
                    name='payPassword'
                    extra={
                        <div>
                            {!payPasswordVisible ? (
                                <EyeInvisibleOutline onClick={() => setPayPasswordVisible(true)} />
                            ) : (
                                <EyeOutline onClick={() => setPayPasswordVisible(false)} />
                            )}
                        </div>
                    }
                >
                    <Input
                        placeholder='请输入新密码'
                        clearable
                        onChange={(val) => setPayPassword(val)}
                        type={payPasswordVisible ? 'text' : 'password'}
                    />
                </Form.Item>
                <Form.Item
                    label='确认密码'
                    name='passwordConfirm'
                    extra={
                        <div>
                            {!payPasswordConfirmVisible ? (
                                <EyeInvisibleOutline onClick={() => setPayPasswordConfirmVisible(true)} />
                            ) : (
                                <EyeOutline onClick={() => setPayPasswordConfirmVisible(false)} />
                            )}
                        </div>
                    }
                >
                    <Input
                        placeholder='请再次输入密码'
                        clearable
                        onChange={(val) => setPayPasswordConfirm(val)}
                        type={payPasswordConfirmVisible ? 'text' : 'password'}
                    />
                </Form.Item>
            </Form>
            <Button block color='primary' size='large' onClick={handleSubmit}>
                提交
            </Button>
            <div className="message" style={{
                padding: "16px"
            }}>
                <div className="title">安全提示</div>
                <p>1、密码至少6位，由大小写字母和数字混合而成，安全性最高。</p>
                <p>2、不要与昵称太相似，容易被人猜到。</p>
                <p>3、不要使用手机电话号码、生日、学号、车牌号、身份证号、银行卡密码等个人信息。</p>
                <p>4、不要使用连续或重复的数字、字母，如：123456、abcdef、111111、aaaaaa等。</p>
                <p>5、定期修改密码以提升账户安全性。</p>
            </div>
        </div>
    )
}