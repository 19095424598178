import { get, post } from "./base";

/**
 * @param {any} data
 */
export function login(data) {
    return post("/manager/login", data)
}

/**
 * @param {any} params
 */
export function getUser(params) {
    return get("/manager/detail", params)
}

/**
 * @param {any} data
 */
export function logout(data) {
    return post("/manager/logout", data)
}