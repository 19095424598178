import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavBar } from "antd-mobile";
import { cnNameMap } from "../../constant/cnNameMap.ts";
import { matchInfo } from "../../interface/index.js";
import { splitArrayByN, options2Str } from "../../utils/index.ts";
import { freeCombine, option2Num, mixedCombine2Free } from "../../constant/combine.ts";
import { calSPlimit, roundToTwo } from "../../utils/MatchBonusCal.ts";

export default function SplitTickets() {
    const navigate = useNavigate();
    const location = useLocation();
    const [type, setType] = useState("");
    const [ticketList, setTicketList] = useState<any[]>([]);
    const [matchGroupById, setMatchGroupById] = useState<{ [key: string]: matchInfo }>({});
    const bet = useMemo(() => {
        return location.state.bet;
    }, [location.state])
    useEffect(() => {
        const { tickets, type } = bet;
        setType(type);
        if(type !== "pickThree" && type !== "champion") {
            const { matchInfo: { matchList = [], oddsForBonus = {} } } = bet;
            const matchIdList = [];
            let _matchGroupById: { [key: string]: matchInfo } = {};
            for(let match of matchList) {
                matchIdList.push(match._id);
                _matchGroupById[match._id] = match;
            }
            setMatchGroupById(_matchGroupById);
            let ticketList = [];
            for(let ticket of tickets) {
                const { items, options, times } = ticket;
                const optionArr = options.split('_');
                var calMaxAry = [];
                var multiCountAry:number[] = [];
                for (let i = 0, len = matchIdList.length; i < len; i++) {
                    let maxVal = 0;
                    multiCountAry[i] = 0;
                    const matchId = matchIdList[i];
                    const match = matchList[i];
                    const [item] = items.filter((item) => item.match === matchId);
                    if(!item) continue;
                    const targetList = item.target as string[];
                    const type = item.type;
                    multiCountAry[i] += targetList.length;
                    for (let target of targetList) {
                        let oddsVal = oddsForBonus[matchId]?.[type]?.[target] || match.odds?.[type]?.[target];
                        maxVal = Math.max(maxVal, oddsVal);
                    }
                    calMaxAry.push(maxVal);
                }
                let maxBonus = 0;
                let mnMount = 0;
                for(let option of optionArr) {
                    let _optionArr;
                    if(freeCombine.includes(option)) {
                        _optionArr = [option];
                    } else {
                        _optionArr = mixedCombine2Free[option];
                    }
                    for(let _option of _optionArr) {
                        const resultMaxArr = splitArrayByN(calMaxAry, option2Num[_option]);
                        for (const result of resultMaxArr) {
                            const _bonux = result.reduce((a, b) => a * b, 1) * 2;
                            maxBonus += calSPlimit(option2Num[_option], roundToTwo(_bonux));
                        }
                        const multiArr = splitArrayByN(multiCountAry, option2Num[_option]);
                        for (const result of multiArr) {
                            mnMount += result.reduce((a, b) => a * b, 1);
                        }
                    }
                }
                if (type === 'beijing') {
                    maxBonus *= 0.65;
                }
                let oneTicketMaxTime = 50;
                if(maxBonus * 50 > 3 * 1000) {
                    oneTicketMaxTime = Math.floor(3 * 1000 / maxBonus) || 1;
                }
                let times50 = Math.floor(times / oneTicketMaxTime);

                while(times50 > 0) {
                    ticketList.push({
                        times: oneTicketMaxTime,
                        num: times50 > 10 ? 10 : times50,
                        options: ticket.options,
                        items: ticket.items,
                        amount: mnMount * oneTicketMaxTime * 2,
                        maxBonus: maxBonus * oneTicketMaxTime
                    })
                    times50 -= 10;
                }
                let remainTimes = ticket.times % oneTicketMaxTime;
                if(remainTimes) {
                    ticketList.push({
                        times: remainTimes,
                        num: 1,
                        options: ticket.options,
                        items: ticket.items,
                        amount: mnMount * remainTimes * 2,
                        maxBonus: maxBonus * remainTimes,
                    })
                }
            }
            setTicketList(ticketList);
        }
    }, [bet])

    function back() {
        navigate(-1);
    }
    return (
        <div className="aui-flexView">
            <NavBar onBack={back}>
                {cnNameMap[type]}
            </NavBar>
            <div className="aui-scrollView content" style={{ padding: 0, marginTop: "10px" }}>
                {/* <div style={{ margin: "14px" }}>
                    <input type="checkbox" className="check-blue" style={{ verticalAlign: "middle" }}/>
                    全选
                </div> */}
                {/* <div style={{ textAlign: "center", margin: "9px 0" }}><h4>方案总金额<span className="font-orange">20</span>元 共<span className="font-money">3</span>张票</h4></div> */}
                {
                    ticketList.map((ticket, index) => {
                        const { times, num, options, items, amount, maxBonus } = ticket;
                        return (
                            <div style={{ margin: "9px 14px" }}>
                                <table className="table-2" style={{ margin: "12px 0" }}>
                                    <tr>
                                        <th style={{ width: "50px" }}>序号</th>
                                        <th style={{ width: "50px" }}>过关</th>
                                        <th>{ cnNameMap[type] }</th>
                                        <th style={{ width: "100px" }}>倍数/票数</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>{ index + 1 }</p>
                                            {/* <p><input type="checkbox" className="check-blue" style={{ verticalAlign: "middle" }}/></p> */}
                                        </td>
                                        <td>{ options2Str(options)}</td>
                                        <td>
                                            {
                                                items.map((item) => {
                                                    const { match, target, type } = item;
                                                    return (
                                                        <p>{matchGroupById[match].matchNumStr} ({ target.map((target) => cnNameMap[`${type}_${target}`] || cnNameMap[target]).join("+") })</p>
                                                    )
                                                })
                                            }
                                        </td>
                                        <td className="font-orange">{times}倍 / {num}张</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}><span>单票金额<span className="font-orange">{ amount }</span>元</span>
                                            <span>单票最高奖金<span className="font-orange">{ maxBonus?.toFixed(2) }元</span></span></td>
                                    </tr>
                                </table>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}