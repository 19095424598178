import { NavBar, Radio, Space, Toast } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getManagerAgentList, postManagerAgentUserTransfer } from "../../axios";
import { RadioValue } from "antd-mobile/es/components/radio";
export default function AgentUserList() {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedUsers = useMemo(() => {
        return location.state.selectedUsers
    }, [location.state])
    const [agentList, setAgentList] = useState<any[]>([]);
    useEffect(() => {
        getManagerAgentList({status: true}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage)
            } else {
                setAgentList(data.result);
            }
        });
    }, []);
    const [selectedAgent, setSelectedAgent] = useState<RadioValue>();
    const handleConfirm = () => {
        postManagerAgentUserTransfer({
            agentId: selectedAgent, users: selectedUsers
        }).then(() => {
            back();
        })
    }
    const back = () => {
        navigate(-1);
    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            background: "#f4f4f4"
        }}>
            <NavBar onBack={back}>
                选择用户
            </NavBar>
            <div style={{ flex: "1 1" }}>
                <Radio.Group onChange={(val: RadioValue) => {
                    setSelectedAgent(val);
                }}>
                    <Space direction='vertical'>
                        {
                            agentList.map(({agent: user}) => {
                                return (
                                    <Radio value={user._id as string}>
                                        <div style={{ padding: "10px 0" }}>
                                            代理：{ user.remark || user.username }
                                        </div>
                                    </Radio>
                                )
                            })
                        }
                    </Space>
                </Radio.Group>
            </div>
            <div style={{
                display: "flex",
                height: "40px",
                alignItems: "center",
                textAlign: "center"
            }}>
                <div style={{
                    flex: "1 1",
                    height: "100%",
                    lineHeight: "40px",
                    background: "#8e8efd",
                    color: "white",
                }} onClick={handleConfirm}>
                    确定
                </div>
            </div>
        </div>
    )
}